<template>
  <div>
    <CoolLightBox
      :items="lightBoxItems"
      :index="index"
      :effect="'swipe'"
      loop
      @close="index = null"
    >
    </CoolLightBox>

    <div class="images-wrapper">
      <div
        class="image"
        v-for="(image, imageIndex) in items"
        :key="imageIndex"
        @click="index = imageIndex"
        :style="{ backgroundImage: 'url(' + image.media[0].original_url + ')' }"
      >
        <div
          @click="downloadWithAxios(image.media[0].original_url, image.name)"
          class="title"
        >
          <h5>{{ image.name.split(".")[0] }}</h5>
          <h5 id="custom-title2">{{ image.subtitle }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import Admin from "@/apis/Admin.js";

export default {
  components: {
    CoolLightBox,
  },
  data() {
    return {
      lightBoxItems: [],
      items: [],
      index: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getCertificates();
    });
  },
  methods: {
    getCertificates() {
      Admin.getCertificates().then((response) => {
        this.items = response.data;
        this.items.forEach((item) => {
          this.lightBoxItems.push({
            src: item.media[0].original_url,
            mediaType: "image",
          });
        });
      });
    },
    forceFileDownload(response, title) {
      console.log(title);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    downloadWithAxios(url, title) {
      axios({
        method: "get",
        url,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response, title);
        })
        .catch(() => console.log("error occured"));
    },
  },
};
</script>

<style>
.image {
  margin-bottom: 65px;
  cursor: pointer;
  transition: 500ms ease;
  background-size: contain;
  background-repeat: no-repeat;
}

.images-wrapper:hover .image:not(:hover) {
  filter: blur(1px) grayscale(1);
  opacity: 0.7;
  transform: scale(0.9);
}

.images-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  width: 100%;
}

.title {
  margin-top: 150%;
  border-bottom: 3px solid #e2e2e2;
}

#custom-title2 {
  font-family: "XMH Flama-Bold";
  height: 40px;
}

.title h5 {
  font-family: "XMH Flama-Basic";
  font-size: 17px;
  margin-bottom: 0px;
  color: #4d4d4e;
  height: 22px;
}

@media (max-width: 992px) {
  .images-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    width: 100%;
  }
}

@media (max-width: 720px) {
  .images-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    width: 100%;
  }
}

@media (max-width: 550px) {
  .images-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    width: 100%;
  }
}
</style>