<template>
  <div class="project__management-page">
    <div class="project__management-breadcrumbs">
      <b-breadcrumb :items="items"></b-breadcrumb>
    </div>
    <div class="project__management-content-mobile">
      <div class="project__management-services-image-mobile">
        <img src="./../../assets/images/servicii/management/main_image.webp" />
        <figcaption class="project__management-figcaption">
          {{ service.image_title }}
        </figcaption>
      </div>
    </div>
    <div class="project__management-content">
      <div class="project__management-services row">
        <div class="col-md-6 project__management-services-left-column">
          <div class="project__management-title">
            <h1 class="line-after">
              {{ $t("homepage.header.menuValues.services") }}
            </h1>
          </div>
          <div class="project__management-subtitle">
            <h2>{{ service.name }}</h2>
          </div>
          <div class="project__management-text" v-html="service.content"></div>
        </div>
        <div class="col-md-6 project__management-services-right-column">
          <div class="project__management-services-image">
            <img :src="mainImage" />
            <figcaption class="project__management-figcaption">
              {{ service.image_title }}
            </figcaption>
          </div>
          <div
            class="project__management-text"
            v-html="service.right_column"
          ></div>
        </div>
      </div>
    </div>
    <ProjectManagementLightbox />
    <div
      class="
        project__management-more-services
        w-100
        d-flex
        justify-content-center
      "
    >
      <div class="other__services-list">
        <div>
          <h1 class="line-after other__services-title">
            <span> {{ $t("homepage.anotherServ") }} </span>
          </h1>
        </div>

        <div class="other__services-item">
          <div
            class="other__services-description"
            v-for="item in services.slice().reverse()"
            :key="item.id"
            v-show="
              item.vue_components === 'large-doors' ||
              item.vue_components === 'ag-partnership'
            "
          >
            <a :href="item.slug + '&component=' + item.vue_components">
              <div v-for="media in item.media" :key="media.id">
                <img
                  v-if="media.collection_name === 'main_image'"
                  class="w-100"
                  :src="media.original_url"
                />
              </div>
              <h5 class="other__services-h5">{{ item.name }}</h5>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectManagementLightbox from "@/components/ProjectManagementLightbox.vue";
import Admin from "@/apis/Admin.js";

export default {
  components: { ProjectManagementLightbox },

  name: "management-project",
  data() {
    return {
      items: [],
      service: [],
      mainImage: "",
      randomServices: [],
      services: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getManagement();
      this.getAllServices();
    });
  },
  methods: {
    getManagement() {
      const serviceName =
        this.$router.history.current.params.service.split("&")[0];
      Admin.getService(serviceName).then((response) => {
        this.service = response.data.service;
        this.randomServices = response.data.random_services;
        this.mainImage = this.service.main_image.original_url;
      });
    },
    getAllServices() {
      Admin.getAllServices().then((response) => {
        this.services = response.data;
      });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.breadcrumb {
  background: transparent;
}

.project__management-breadcrumbs {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: url(./../../assets/images/servicii/management/bread-management.webp);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: calc(100vw / 4);
  max-height: 100%;
}

.breadcrumb span {
  font-size: 27px;
  text-transform: uppercase;
  font-weight: 500;
}

.project__management-page {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.project__management-services {
  width: 1171px;
}

.project__management-content {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 60px 0px 30px 0px;
}

.project__management-content-mobile {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 60px 0px 30px 0px;
}

.project__management-services-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-top: 6px;
}

.project__management-title h1 {
  color: #252525;
  font-family: "XMH Flama-Medium";
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.project__management-subtitle h2 {
  color: #00aad7;
  font-family: "XMH Flama-Medium";
  font-size: 34px;
  line-height: 40.93px;
  letter-spacing: -0.5px;
  margin-bottom: 30px;
}

#project__management-first-paragraph {
  color: #4d4d4d;
  font-family: "XMH Flama-Medium";
  font-size: 17px;
}

.project__management-text {
  width: 100%;
}

.project__management-text h5 {
  letter-spacing: -0.38px;
  line-height: 23px;
  font-size: 16px;
  color: #424242;
  margin-bottom: 0px;
  margin-top: 25px;
  font-family: "OpenSans-Regular";
  font-weight: bold;
}

.project__management-text h4 {
  font-family: "XMH Flama-Medium";
  font-size: 19px;
  margin-top: 47px;
  margin-bottom: 0px;
}

.project__management-text p {
  line-height: 23px;
  font-size: 14px;
  color: #424242;
  font-family: "OpenSans-Regular";
}

.c-parag p {
  font-family: "OpenSans-Semibold";
  font-size: 16px;
  color: #424242;
}

.project__management-figcaption {
  width: auto;
  height: 65px;
  background-color: #e6e7e2;
  color: #72726f;
  padding-left: 41px;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.project__management-services-right-column {
  margin-top: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.line-after::after {
  margin-right: 0px;
}

.project__management-gallery {
  margin: 30px 0px;
}

.project__management-gallery-list {
  width: 1171px;
  --gap: 25px;
  --num-cols: 3;
  --row-height: 200px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  gap: var(--gap);
  margin-bottom: 80px;
}

.project__management-gallery-list-mobile {
  width: 1171px;
  --gap: 20px;
  --num-cols: 1;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  gap: var(--gap);
  margin-bottom: 60px;
}

.project__management-gallery-list-mobile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-grid-col-2 {
  grid-column: span 2;
}

.image-grid-col-3 {
  grid-column: span 3;
}

.image-grid-row-2 {
  grid-row: span 2;
}

#custom-style {
  font-size: 20px;
  font-family: "XMH Flama-Medium";
  margin-bottom: 0px;
}

.project__management-gallery-list img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project__management-more-services {
  background-color: #e6e7e2;
}

.project__management-services-list {
  width: 1171px;
  padding: 50px 0px;
}

.project__management-services-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 0px;
}

.project__management-h5 {
  font-size: 16px;
  margin: 10px 0px;
}

.other__services-list {
  width: 1171px;
}

.other__services-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 0px;
  margin-bottom: 80px;
}

.other__services-description h5 a {
  color: #252525;
  font-family: "XMH Flama-Medium";
  font-size: 22px;
  margin: 17px 0px;
}

.other__services-title {
  color: #252525;
  font-family: "XMH Flama-Medium";
  font-size: 24px;
  margin-bottom: 35px !important;
  text-transform: uppercase;
  margin-top: 70px;
}

@media (min-width: 1760px) {
  #project__management-first-paragraph {
    width: 571px;
  }
}

@media (max-width: 1366px) {
  .project__management-services,
  .other__services-list,
  .project__management-gallery-list {
    width: 85% !important;
  }
}

@media (max-width: 1300px) {
  .display-desktop {
    display: none;
  }
  .project__management-services-right-column {
    justify-content: flex-start;
  }
}

@media (max-width: 992px) {
  .project__management-services,
  .project__management-content {
    width: 100%;
  }
  .project__management-title h1 {
    font-size: 20px;
    margin-bottom: 25px;
  }
  .project__management-subtitle h2 {
    font-size: 28px;
    margin-bottom: 25px;
  }
  .c-parag p {
    font-size: 13px;
    line-height: 21px;
  }
  .project__management-text p {
    line-height: 20px;
    font-size: 13px;
  }
  .project__management-text h5 {
    font-size: 14px;
  }
  .project__management-text h4 {
    font-size: 16px;
  }
  .other__services-title {
    font-size: 20px !important;
    margin-top: 55px;
  }
  .other__services-h5 {
    font-size: 18px !important;
  }
  .project__management-gallery-list-mobile {
    width: 85% !important;
  }
}

@media (min-width: 767px) {
  .project__management-services-image-mobile,
  .project__management-content-mobile {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .project__management-services-image {
    display: none !important;
  }
  .project__management-content-mobile,
  .project__management-services-image-mobile img {
    width: 100%;
  }
  .project__management-services-image-mobile {
    width: 85%;
  }
  .project__management-content {
    margin: 35px 0px 30px 0px;
  }

  .project__management-content-mobile {
    margin-top: 45px;
  }
  .project__management-title h1 {
    margin-bottom: 20px;
  }
}
@media (max-width: 380px) {
  .project__management-subtitle h2 {
    line-height: 32px;
  }
}
</style>