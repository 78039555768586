<template>
  <div>
    <div
      v-if="!isMobile()"
      class="assembly__structures-gallery w-100 d-flex justify-content-center"
    >
      <CoolLightBox
        :items="lightBoxItems"
        :index="index"
        :effect="'swipe'"
        loop
        @close="index = null"
      >
      </CoolLightBox>

      <div class="assembly__structures-gallery-list">
        <div
          class="assembly__structures-img"
          v-for="(image, imageIndex) in service.media.slice(0, 4)"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <img class="image" :src="image.original_url" />
        </div>
        <div
          class="assembly__structures-img image-grid-col-2"
          v-for="(image, imageIndex) in service.media.slice(4, 5)"
          :key="imageIndex"
          @click="index = imageIndex + 4"
        >
          <img class="image" :src="image.original_url" />
        </div>
      </div>
    </div>
    <div
      v-if="isMobile()"
      class="assembly__structures-gallery w-100 d-flex justify-content-center"
    >
      <CoolLightBox
        :items="lightBoxItems"
        :index="index"
        :effect="'swipe'"
        loop
        @close="index = null"
      >
      </CoolLightBox>

      <div class="assembly__structures-gallery-list-mobile">
        <div
          class="assembly__structures-img"
          v-for="(image, imageIndex) in service.media"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <img class="image" :src="image.original_url" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import Admin from "@/apis/Admin.js";

export default {
  components: {
    CoolLightBox,
  },
  data() {
    return {
      service: [],
      lightBoxItems: [],
      index: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getAssembly();
    });
  },
  methods: {
    getAssembly() {
      const serviceName =
        this.$router.history.current.params.service.split("&")[0];
      Admin.getService(serviceName).then((response) => {
        this.service = response.data.service;
        this.service.media.forEach((item) => {
          this.lightBoxItems.push({
            src: item.original_url,
            mediaType: "image",
          });
        });
      });
    },
    forceFileDownload(response, title) {
      console.log(title);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    downloadWithAxios(url, title) {
      axios({
        method: "get",
        url,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response, title);
        })
        .catch(() => console.log("error occured"));
    },
  },
};
</script>

<style>
.image {
  margin-bottom: 0px !important;
}
.assembly__structures-gallery {
  margin: 90px 0px;
}

.assembly__structures-gallery-list {
  width: 1171px;
  --gap: 30px;
  --num-cols: 3;
  --row-height: 200px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  gap: var(--gap);
}

.assembly__structures-gallery-list-mobile {
  width: 1171px;
  --gap: 30px;
  --num-cols: 1;
  --row-height: 200px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  gap: var(--gap);
}

.assembly__structures-gallery-list-mobile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-grid-col-2 {
  grid-column: span 2;
}

.assembly__structures-gallery-list img {
  width: 100%;
}

@media (max-width: 992px) {
  .assembly__structures-gallery-list-mobile {
    width: 85% !important;
  }
}
</style>