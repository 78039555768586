<template>
  <div class="content__services d-flex flex-column">
    <div>
      <h1 class="content__services-title">
        {{ $t("homepage.header.menuValues.services") }}
      </h1>
    </div>
    <div class="content__services__services-presentation">
      <div
        class="content__services__description"
        v-for="service in services"
        :key="service.id"
      >
        <hr
          style="margin-bottom: 13px; border-top: 2px solid rgba(0, 0, 0, 0.1"
        />
        <h5
          class="content__services__header text-uppercase"
          v-bind:class="service.vue_components"
        >
          <a
            :href="
              '/' +
              serviceLangs[lang] +
              '/' +
              service.slug +
              '&component=' +
              service.vue_components
            "
          >
            {{ service.name }}
          </a>
        </h5>
        <a
          :href="
            '/' +
            serviceLangs[lang] +
            '/' +
            service.slug +
            '&component=' +
            service.vue_components
          "
        >
          <div v-for="media in service.media" :key="media.id">
            <img
              v-if="media.collection_name === 'right_image'"
              class="mw-100"
              :src="media.original_url"
            />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Admin from "@/apis/Admin.js";
export default {
  data() {
    return {
      services: [],
      serviceLangs: {
        ro: "servicii",
        en: "services",
        de: "leistungen",
        fr: "services",
      },
      lang: localStorage.getItem("lang"),
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getAllServices();
    });
  },
  methods: {
    getAllServices() {
      Admin.getAllServices().then((response) => {
        this.services = response.data;
      });
    },
  },
};
</script>

<style scoped>
.project-management {
  width: 150px;
}

.large-doors {
  width: 200px;
}

.assembly-structures {
  width: 300px;
}

.industrial-walls {
  width: 280px;
}

.content__services {
  margin: 55px 0px 30px 0px !important;
}

.content__services__services-presentation {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.content__services__description img {
  width: 370px;
  height: auto;
}

@import "../assets/styles/homepage.css";
</style>