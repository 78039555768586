<template>
  <div class="navigation__header sticky-top">
    <div
      class="navigation__header-topbar"
      :class="{ hide_langs: scrollPosition > 0.5 }"
    >
      <div class="navigation__header-item">
        <div class="custom-nav">
          <SwitchLanguage />

          <div class="navigation__header-search" v-b-modal.modal-search>
            <img src="../assets/images/search-icon.webp" />
          </div>
        </div>
        <div>
          <b-modal id="modal-search" :title="$t('search.title')" hide-footer>
            <div class="search-input">
              <input
                type="text"
                :placeholder="$t('search.placeholder')"
                name="term"
                class="form-control"
                v-model="term"
              />
            </div>
            <div
              class="results"
              v-for="(result, index) in results"
              :key="index"
            >
              <div class="result">
                <div class="result-title">
                  <router-link
                    :to="serviceLangs[lang] + '/' + result.slug + '&component=' + result.component "
                    target="_blank"
                    v-if="result.type === 'Service'"
                    >{{ result.name }}
                  </router-link>
                  <router-link
                    :to="'/portofoliu/' + result.slug"
                    target="_blank"
                    v-else-if="result.type === 'Portofolio'"
                    >{{ result.name }}
                  </router-link>
                </div>
                <div
                  class="result-description"
                  v-html="result.content.substring(0, 210) + ' ...'"
                ></div>
              </div>
            </div>
          </b-modal>
        </div>
      </div>
    </div>

    <div class="navigation__header-main">
      <b-navbar toggleable="lg" type="dark">
        <b-navbar-brand href="/"
          ><img
            src="../assets/images/logo-light.webp"
            style="width: 120px; height: auto"
        /></b-navbar-brand>

        <div class="mobile-right-menu">
        
            <SwitchLanguageMobile />

          <div
            class="navigation__header-search search-mobile"
            v-b-modal.modal-search
          >
            <img src="../assets/images/search-topbar.webp" />
          </div>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        </div>

        <b-collapse id="nav-collapse" v-model="showCollapse" is-nav>
          <b-navbar-nav class="nav-menu">
            <div v-for="item in menuItems" :key="item.id">
              <b-nav-item-dropdown
                ref="dropdown"
                :text="item.name"
                class="nav__services"
                v-if="item.sort === 1"
                
              >
                <li v-for="child in item.childs" :key="child.id">
                  <a
                    active-class="active-class"
                    :href="
                      '/' +
                      serviceLangs[lang] +
                      '/' +
                      child.service.slug +
                      '&component=' +
                      child.service.vue_components
                    "
                    >{{ child.service.name }}</a
                  >
                </li>
              </b-nav-item-dropdown>
              <b-nav-item
                active-class="active-class"
                class="company"
                v-if="item.sort === 2"
                :to="'/company/' + item.name.toLowerCase().replace(' ', '-')"
                >{{ item.name }}</b-nav-item
              >
            </div>

            <b-nav-item active-class="active-class" to="/portofoliu">{{
              $t("homepage.header.menuValues.portfolio")
            }}</b-nav-item>
            <b-nav-item class="nav-item" v-on:click="scrollDown()">{{
              $t("homepage.header.menuValues.contact")
            }}</b-nav-item>
          </b-navbar-nav>
        </b-collapse>

        <b-navbar-nav class="s-icon">
          <div class="social-media">
            <a
              v-for="(social, index) in socials"
              :href="social.link"
              target="_blank"
              :key="index"
            >
              <img
                class="social-media-icon"
                :src="social.header_icon.original_url"
              />
            </a>
          </div>
        </b-navbar-nav>
      </b-navbar>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Search from "@/apis/Search";
import Admin from "@/apis/Admin.js";
import SwitchLanguage from "@/components/SwitchLanguage.vue";
import SwitchLanguageMobile  from "@/components/SwitchLanguageMobile.vue";
export default {
  name: "navigation-header",
  components: {
    SwitchLanguage,
    SwitchLanguageMobile
  },

  data() {
    return {
      title: "NavigationHeader",
      isHidden: true,
      searchHidden: true,
      showCollapse: false,
      term: "",
      results: [],
      socials: [],
      langs: ["RO", "EN", "DE", "FR"],
      menuItems: [],
      serviceLangs: {
        ro: "servicii",
        en: "services",
        de: "leistungen",
        fr: "les-services",
      },
      lang: localStorage.getItem("lang"),
      scrollPosition: null,
    };
  },

  watch: {
    $route() {
      this.showCollapse = false;
    },

    term: _.debounce(function (newValue) {
      if (newValue) {
        this.results = [];
      }
      this.search();
      this.term = newValue;
    }, 300),
  },

  mounted() {
    this.$nextTick(() => {
      this.getSocial();
      this.getMenu();
      window.addEventListener("scroll", this.updateScroll);
    });
  },

  methods: {
    async search() {
      let response = await Search.search(this.term);
      response.data.data.forEach((search) => {
        this.results.push({
          provider: search.provider,
          slug: search.slug,
          content: search.content,
          name: search.name,
          type: search.type,
          component:search.vue_components,
        });
      });
    },

    updateScroll() {
      this.scrollPosition = window.scrollY;
    },

    scrollDown() {
      window.scroll({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    },

    getSocial() {
      Admin.getSocial().then((response) => {
        this.socials = response.data;
      });
    },

    getMenu() {
      Admin.getMenu().then((response) => {
        this.menuItems = response.data;
      });
    },
  },
};
</script>

<style>
.hide_langs {
  display: none !important;
  scroll-behavior: smooth;
}

.company:hover {
  height: 40px;
}
.navbar-nav li:hover > ul.dropdown-menu {
  display: block;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
}

/* rotate caret on hover */
.dropdown-menu > li > a:hover:after {
  text-decoration: underline;
  transform: rotate(-90deg);
}
.results {
  display: flex;
  flex-direction: column;
}

.result {
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e8ed;
  padding: 10px;
  margin-bottom: 10px;
}

.search-input {
  margin-bottom: 15px;
}

.result-title {
  font-family: "XMH Flama-Medium";
  font-size: 20px;
}

.result-description {
  font-family: "XMH Flama-Book";
  font-size: 15px;
}

.search-container button {
  padding: 6px 15px;
  font-size: 17px;
  border: none;
  cursor: pointer;
  background: white;
}

button img {
  width: 15px;
}

.search-container button:hover {
  background: #ccc;
}

.language-mobile {
  color: white;
  margin-left: 120px;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: none;
  font-size: 22px;
}

.language-mobile:focus {
  color: #00aad7 !important;
}

.nav-menu {
  width: 510px;
  justify-content: space-between;
}

.navigation__header {
  width: 100%;
  background-color: #252525;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.custom-nav {
  width: 160px;
  display: flex;
  justify-content: space-between;
}

.navbar-brand {
  height: 60px;
}

.nav-link {
  color: #cacaca !important;
}

.nav-link:focus-visible {
  outline: none !important;
}

.navbar-collapse {
  display: flex;
  justify-content: center;
}

.s-icon {
  padding-bottom: 10px;
  width: 9%;
}

.navbar-toggler {
  border: none !important;
  padding: 19px 10px 0px 10px !important;
}

.navigation__header-main {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.navbar {
  width: 1171px;
  padding: 0 !important;
}

.navbar-expand-lg {
  display: flex;
  justify-content: space-between !important;
}

.social-media {
  display: flex;
  justify-content: space-between;
  filter: brightness(0) invert(1);
  width: 100%;
}

.social-media-icon {
  width: 15px !important;
  height: 15px !important;
}

.nav-item a {
  font-size: 14px;
  text-transform: uppercase;
  font-family: "XMH Flama-Book";
}

.nav__services {
  font-size: 14px;
  text-transform: uppercase;
}

.nav-item span {
  font-family: "XMH Flama-Book";
  color: #cacaca;
}

.dropdown-menu {
  background-color: #595959 !important;
  width: 395px !important;
  padding: 5px !important;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2) !important;
  border-radius: 0 !important;
  border: none !important;
  margin: 0.4rem 0 0 !important;
}

.dropdown-menu a {
  color: #cacaca;
  padding: 3px 13px;
  text-decoration: none !important;
  display: block;
  font-family: "XMH Flama-Book";
  font-size: 12px !important;
}

.dropdown-menu li a:hover {
  color: #dddddd;
}

.nav-item {
  padding-bottom: 20px;
  font-family: "XMH Flama-Book";
}

.nav-item:hover {
  background-color: #595959;
}

.navigation__header-topbar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.navigation__header-item {
  width: 1171px;
  color: white;
  display: flex;
  justify-content: flex-end;
}

#mobile-languages {
  width: 75%;
  display: flex;
  justify-content: flex-end;
}

.navigation__header-search img {
  filter: brightness(0) invert(1);
  width: 18px;
  height: 18px;
}

.navigation__header-search {
  background-color: #00aad6;
  padding: 15px 10px 5px 10px;
  margin-top: -10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.dropdown-toggle::after {
  display: none !important;
}

.active-class {
  background-color: #595959;
  padding-right: 21px !important;
  padding-left: 21px !important;
  padding-bottom: 6px !important;
  padding-top: 6px !important;
}

@media (min-width: 992px) {
  .nav-menu {
    height: 40px;
  }
  .dropdown-menu {
    top: 44px !important;
  }
  .language-mobile,
  .search-mobile,
  .languages-mobile,
  .search-container {
    display: none;
  }
}
</style>
<style scoped>
@import "../assets/styles/header.css";
</style>