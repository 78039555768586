import ro from './ro.json'
import en from './en.json'
import de from './de.json'
import fr from './fr.json'

export const defaultLocale = 'ro'

export const languages = {
    ro: ro,
    en: en,
    de: de,
    fr: fr,
}