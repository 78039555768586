import { render, staticRenderFns } from "./CompanyCsr.vue?vue&type=template&id=3b6e9000&scoped=true&"
import script from "./CompanyCsr.vue?vue&type=script&lang=js&"
export * from "./CompanyCsr.vue?vue&type=script&lang=js&"
import style0 from "./CompanyCsr.vue?vue&type=style&index=0&id=3b6e9000&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b6e9000",
  null
  
)

export default component.exports