<template>
  <div class="navigation__header-languages" id="switch-language">
    <select
      @change="updateLanguage()"
      v-model="$i18n.locale"
      :size="langs.length"
      v-if="!isSafari()"
      class="langs-select hide-scrollbar d-inline"
    >
      <option v-for="(lang, i) in langs" :key="`lang-${i}`" :value="lang">
        {{ lang }}
      </option>
    </select>
    <select
      v-else
      @change="updateLanguage()"
      v-model="$i18n.locale"
      class="safari-langs-select"
    >
      <option v-for="(lang, i) in langs" :key="`lang-${i}`" :value="lang">
        {{ lang }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  name: "SwitchLocale",
  data() {
    return {
      langs: ["ro", "en", "de", "fr"],
      currentLang: "",
    };
  },
  methods: {
    updateLanguage() {
      localStorage.setItem("lang", this.$i18n.locale);
      window.location.href = "/";
    },
    isSafari() {
      if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      this.$i18n.locale = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", this.$i18n.locale);
    }
  },
  watch: {
    currentLang(newLang) {
      localStorage.setItem("lang", newLang);
    },
  },
};
</script>
<style>
*:focus {
  outline: none;
}

select {
  background-color: transparent;
  border: 0;
  padding: 10px;
  margin: -5px -20px -5px -5px;
}

#switch-language {
  overflow: hidden !important;
  width: 100%;
}

#switch-language select option {
  display: inline-block;
  font-size: 14px;
  padding: 0px 5px;
  background: none !important;
  color: white;
}

.langs-select {
  color: white;
  overflow: hidden !important;
  height: 35px;
  padding: 5px 0px;
  border-bottom: 1px solid #a4a4a3;
  text-transform: uppercase;
}

.langs-select option {
  text-transform: uppercase;
}

.langs-select option:checked {
  color: #259bc3 !important;
}

/*Mozilla Firefox*/
.hide-scrollbar {
  overflow: auto;
  /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

.safari-langs-select {
  color: white;
  text-transform: uppercase;
  background-color: #252525;
}

@media (max-width: 992px) {
  .langs-select {
    border-bottom: none !important;
  }
  select {
    margin: 0px !important;
  }
}
</style>