import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from "../views/HomePage.vue";
import CompanyPage from "../views/pages/CompanyPage.vue";
import Portfolio from "../views/pages/PortfolioPage.vue";
import PortfolioWork from "../views/portfolio/PortfolioWork.vue";
import ProjectManagement from "../views/services/ProjectManagement.vue";
import LargeDoors from "../views/services/LargeDoors.vue";
import AssemblyStructures from "../views/services/AssemblyStructures.vue";
import IndustrialWalls from "../views/services/IndustrialWalls.vue";
import MaintenancePage from "../views/services/MaintenancePage.vue";
import AgPartnership from "../views/services/AgPartnership.vue";
import CareersPage from "../views/pages/CareersPage.vue";
import ApplyNow from "../components/careers/jobs-title.vue";
import CataloguePage from "../views/pages/CataloguePage.vue";
import CertificationsPage from "../views/pages/CertificationsPage.vue";
import SearchSingle from "../views/pages/SearchSingle.vue";

Vue.use(VueRouter);

const componentName = window.location.href.split('component=');

const SERVICES_COMPONENTS = {
  'project-management': ProjectManagement,
  'large-doors': LargeDoors,
  'assembly-structures': AssemblyStructures,
  'industrial-walls': IndustrialWalls,
  'maintenance-page': MaintenancePage,
  'ag-partnership': AgPartnership,
}

const servicesLang = {
  'ro': 'servicii',
  'en': 'services',
  'de': 'leistungen',
  'fr': 'les-services'
}

const lang = localStorage.getItem('lang');

const routes = [
  {
    path: "/",
    name: "home-page",
    component: HomePage
  },
  {
    path: "/company/:slug",
    name: "company-page",
    component: CompanyPage
  },
  {
    path: "/portofoliu",
    name: "portfolio-page",
    component: Portfolio
  },
  {
    path: `/${servicesLang[lang]}/:service`,
    name: componentName[1],
    component: SERVICES_COMPONENTS[componentName[1]]
  },

  {
    path: "/cariere",
    name: "careers-page",
    component: CareersPage
  },
  {
    path: "/cariere/:title",
    name: "jobs-title",
    component: ApplyNow
  },
  {
    path: "/portofoliu/:slug",
    name: "portfolio-work",
    component: PortfolioWork
  },
  {
    path: "/cautare/:tip/:slug",
    name: "search-single",
    component: SearchSingle
  },
  {
    path: "/catalog",
    name: "catalogue-page",
    component: CataloguePage
  },
  {
    path: "/certificari",
    name: "certifications-page",
    component: CertificationsPage
  },
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  routes,
})

export default router