<template>
<div>
  <vue-easy-lightbox
    :visible="visible"
    :imgs="lightBoxItems"
    :index="index"
    @hide="onHideGallery"
  >
  <template v-slot:toolbar></template>
  </vue-easy-lightbox>
  <div class="company__csr">
    <carousel
      :perPage="4"
      :paginationEnabled="false"
      :navigationEnabled="true"
      :loop="true"
      :mouseDrag="true"
      navigationNextLabel="<i class='carousel-control-next-icon'></i>"
      navigationPrevLabel="<i class='carousel-control-prev-icon'></i>"
    >
      <slide class="company__csr-list" v-for="(item, index) in gallery" :key="item.id">
        <div class="company__csr-image" @click="handleGallery(index)">
          <img
            :src="item[index].main_image"
            class="company__csr-custom-img"
          />
          <div class="company__csr-text">
          <p class="company__csr-custom-text">{{ item[index].gallery_name }}</p>
        </div>
        </div>
        
      </slide>
        
    </carousel>
    <div class="company__csr-career">
      <div class="company__csr-application">
        <h4 class="company__csr-subtitle">
          {{ $t("companyPage.joinTeam") }}
        </h4>
        <router-link to="/cariere"
          ><button class="company__csr-button">{{ $t("companyPage.apply") }}</button></router-link
        >
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Admin from "@/apis/Admin.js";

export default {
  data() {
    return {
      visible: false,
      index: 0,
      gallery: [],
      lightBoxItems: []
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getCSR();
    });
  },
  methods: {
    getCSR() {
      Admin.getCSR().then((response) => {
        response.data.forEach((gallery, index) => {
          this.gallery.push({
            [index]:{gallery: [ gallery.media], 'gallery_name': gallery.name, 'main_image': gallery.main_media.original_url},
          });
        });
      });
    },

    handleGallery(index) {
      this.gallery[index][index].gallery.forEach((images) => {
        images.forEach((image) => {
          this.lightBoxItems.push(image.original_url);
        });
      });

      this.visible = true;
    },

    onHideGallery() {
      this.lightBoxItems = [];
      this.visible = false;
    }
  },
};
</script>

<style scoped>
@import "../assets/styles/company-csr.css";

.vel-modal {
  background-color: #1e1e1ee6 !important; 
}

</style>