<template>
  <div class="carousel__partners">
    <infinite-slide-bar duration="40s">
      <div class="items">
        <div class="item" v-for="item in items" :key="item.id">
          <div class="item-img">
            <img :src="item.media[0].original_url" />
          </div>
        </div>
      </div>
    </infinite-slide-bar>
  </div>
</template>

<script>
import Admin from "@/apis/Admin.js";
import InfiniteSlideBar from "vue-infinite-slide-bar";

export default {
  components: {
    InfiniteSlideBar,
  },

  data() {
    return {
      items: [],
      index: 0,
      direction: null,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.getHomepagePartners();
    });
  },

  methods: {
    getHomepagePartners() {
      Admin.getHomepagePartners().then((response) => {
        this.items = response.data;
      });
    },
  },
};
</script>

<style>
.items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 115px;
}

.item {
  width: 250px;
}

.item-img {
  display: flex;
  justify-content: center;
  width: 100%;
}

.carousel__partners {
  overflow: hidden;
}
</style>