<template>
  <div class="company__team">
    <div>
      <h1 class="line-after company__team-title">
        <span> {{ $t("companyPage.teams") }} </span>
      </h1>
    </div>
    <!-- <div class="team-text">
      <p class="team-p">
        {{ $t("companyPage.parag1") }}
      </p>
      <p class="team-p">
        {{ $t("companyPage.parag2") }}
      </p>
      <p class="team-p">
        {{ $t("companyPage.parag1") }}
      </p>
    </div> -->
    <div class="company__team-list">
      <div
        class="company__team-item"
        v-for="member in members"
        :key="member.id"
      >
        <div class="company__team-image">
          <img :src="member.media[0].original_url" />
        </div>
        <div class="company__team-description">
          <h4 class="company__team-name">
            <b>{{ member.full_name }}</b>
          </h4>
          <p class="company__team-job">{{ member.role }}</p>
          <div
            class="company__team-email"
            v-html="member.short_description"
          ></div>
        </div>
      </div>
    </div>
    <div class="company__team-responsability" id="responsability">
      <div>
        <h1 class="line-after company__team-responsability-title">
          <span> {{ $t("companyPage.socialResp") }} </span>
        </h1>
      </div>
      <div class="company__team-responsability-text">
        <p>
          {{ $t("companyPage.textCsr") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Admin from "@/apis/Admin.js";

export default {
  data() {
    return {
      members: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getTeam();
    });
  },
  methods: {
    getTeam() {
      Admin.getTeam().then((response) => {
        this.members = response.data;
      });
    },
  },
};
</script>

<style scoped>
@import "../assets/styles/company-team.css";
</style>