<template>
  <div>
    <CoolLightBox
      :items="lightBoxItems"
      :index="index"
      :effect="'swipe'"
      loop
      @close="index = null"
    >
    </CoolLightBox>

    <div class="large__doors-gallery w-100 d-flex justify-content-center">
      <div class="large__doors-gallery-list">
        <div
          class="large__doors-img1"
          v-for="(image, imageIndex) in service.media.slice(0, 1)"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <img :src="image.original_url" />
        </div>
        <div
          class="large__doors-img2"
          v-for="(image, imageIndex) in service.media.slice(1, 2)"
          :key="imageIndex"
          @click="index = imageIndex + 1"
        >
          <img :src="image.original_url" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import Admin from "@/apis/Admin.js";

export default {
  components: {
    CoolLightBox,
  },
  data() {
    return {
      lightBoxItems: [],
      service: [],
      index: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getDoors();
    });
  },
  methods: {
    getDoors() {
      const serviceName =
        this.$router.history.current.params.service.split("&")[0];
      Admin.getService(serviceName).then((response) => {
        this.service = response.data.service;
        this.service.media.forEach((item) => {
          this.lightBoxItems.push({
            src: item.original_url,
            mediaType: "image",
          });
        });
      });
    },
    forceFileDownload(response, title) {
      console.log(title);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    downloadWithAxios(url, title) {
      axios({
        method: "get",
        url,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response, title);
        })
        .catch(() => console.log("error occured"));
    },
  },
};
</script>

<style>
.large__doors-gallery {
  margin: 30px 0px;
}

.large__doors-gallery-list {
  width: 1171px;
  display: grid;
  --gap: 30px;
  --num-cols: 1;
  --row-height: 200px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  gap: var(--gap);
  margin-bottom: 95px;
}
@media (max-width: 992px) {
  .large__doors-gallery-list {
    width: 85% !important;
  }
}

.large__doors-gallery-list img {
  width: 100%;
}

.image {
  margin-bottom: 65px;
  cursor: pointer;
  transition: 500ms ease;
  background-size: contain;
  background-repeat: no-repeat;
}

.images-wrapper:hover .image:not(:hover) {
  filter: blur(1px) grayscale(1);
  opacity: 0.7;
  transform: scale(0.9);
}

.images-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 0px;
  width: 100%;
}

.title {
  margin-top: 150%;
  border-bottom: 3px solid #e2e2e2;
}

.title h5 {
  font-family: "XMH Flama", sans-serif;
  height: 50px;
  font-size: 17px;
}

@media (max-width: 992px) {
  .images-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 0px;
    width: 100%;
  }
}

@media (max-width: 720px) {
  .images-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 0px;
    width: 100%;
  }
}

@media (max-width: 550px) {
  .images-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 0px;
    width: 100%;
  }
}
</style>