<template>
  <div class="portfolio__home">
    <div>
      <h1 class="line-after portfolio__title">
        <span> <router-link to="/portofoliu">{{ $t("homepage.header.menuValues.portfolio") }} </router-link></span>
      </h1>
    </div>
    <div class="portfolio__home-list">
      <div class="portfolio__home-item">
        <div class="work-search">
          <router-link to="/portofoliu/corten-ana-tower">
            <div class="portfolio__home-search">
              <img src="../assets/images/search-topbar.webp" />
            </div>
            <img
              class="portfolio__home-image"
              src="../assets/images/portfolio/port1.webp"
          /></router-link>
        </div>
        <div class="portfolio__home-description">
          <h5 class="portfolio__home-title">
            <a href="/portofoliu/corten-ana-tower">
              Corten Ana Tower
              <span class="custom-desc">București, 2020</span></a
            >
          </h5>
          <h6 class="portfolio__home-title--secondary">
            <a href="/portofoliu/corten-ana-tower">{{
              $t("homepage.filters.category.buildings")
            }}</a>
          </h6>
        </div>
      </div>

      <div class="portfolio__home-item">
        <div class="work-search">
          <router-link to="/portofoliu/totem-pleiada">
            <div class="portfolio__home-search">
              <img src="../assets/images/search-topbar.webp" />
            </div>
            <img
              class="portfolio__home-image"
              src="../assets/images/portfolio/port2.webp"
          /></router-link>
        </div>
        <div class="portfolio__home-description">
          <h5 class="portfolio__home-title">
            <a href="/portofoliu/totem-pleiada">
              Totem Pleiada <span class="custom-desc">Iași, 2020</span></a
            >
          </h5>
          <h6 class="portfolio__home-title--secondary">
            <a href="/portofoliu/totem-pleiada">{{
              $t("homepage.filters.category.buildings")
            }}</a>
          </h6>
        </div>
      </div>

      <div class="portfolio__home-item">
        <div class="work-search">
          <router-link to="/portofoliu/termototal">
            <div class="portfolio__home-search">
              <img src="../assets/images/search-topbar.webp" />
            </div>
            <img
              class="portfolio__home-image"
              src="../assets/images/portfolio/port3.webp"
          /></router-link>
        </div>
        <div class="portfolio__home-description">
          <h5 class="portfolio__home-title">
            <a href="/portofoliu/termototal">
              Termototal <span class="custom-desc">Gherla, 2018</span></a
            >
          </h5>
          <h6 class="portfolio__home-title--secondary">
            <a href="/portofoliu/termototal">{{
              $t("homepage.filters.category.foodProd")
            }}</a>
          </h6>
        </div>
      </div>

      <div class="portfolio__home-item">
        <div class="work-search">
          <router-link to="/portofoliu/zeelandia">
            <div class="portfolio__home-search">
              <img src="../assets/images/search-topbar.webp" />
            </div>
            <img
              class="portfolio__home-image"
              src="../assets/images/portfolio/port4.webp"
          /></router-link>
        </div>
        <div class="portfolio__home-description">
          <h5 class="portfolio__home-title">
            <a href="/portofoliu/zeelandia">
              Zeelandia <span class="custom-desc">Iași, 2010</span></a
            >
          </h5>
          <h6 class="portfolio__home-title--secondary">
            <a href="/portofoliu/zeelandia">{{
              $t("homepage.filters.category.foodProd")
            }}</a>
          </h6>
        </div>
      </div>

      <div class="portfolio__home-item">
        <div class="work-search">
          <router-link to="/portofoliu/um-hangar-iv">
            <div class="portfolio__home-search">
              <img src="../assets/images/search-topbar.webp" />
            </div>
            <img
              class="portfolio__home-image"
              src="../assets/images/portfolio/port5.webp"
          /></router-link>
        </div>
        <div class="portfolio__home-description">
          <h5 class="portfolio__home-title">
            <a href="/portofoliu/um-hangar-iv">
              UM Hangar IV <span class="custom-desc">Otopeni, 2021</span></a
            >
          </h5>
          <h6 class="portfolio__home-title--secondary">
            <a href="/portofoliu/um-hangar-iv">{{
              $t("homepage.filters.category.buildings")
            }}</a>
          </h6>
        </div>
      </div>

      <div class="portfolio__home-item">
        <div class="work-search">
          <router-link to="portofoliu/pod-suspendat-braila">
            <div class="portfolio__home-search">
              <img src="../assets/images/search-topbar.webp" />
            </div>
            <img
              class="portfolio__home-image"
              src="../assets/images/portfolio/port6.webp"
          /></router-link>
        </div>
        <div class="portfolio__home-description">
          <h5 class="portfolio__home-title">
            <a href="/portofoliu/pod-suspendat-braila">
              CNAIR Pod Dunăre <span class="custom-desc">Brăila, 2022</span></a
            >
          </h5>
          <h6 class="portfolio__home-title--secondary">
            <a href="/portofoliu/pod-suspendat-braila">{{
              $t("homepage.filters.category.buildings")
            }}</a>
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.portfolio__home-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 50px;
}

.custom-desc {
  color: #252525;
  font-size: 20px;
  font-family: "XMH Flama-Book";
}

.portfolio__home-search {
  background-color: #00aad6;
  padding: 3px 9px 4px 9px;
  position: absolute;
  bottom: 6%;
  right: 4%;
}

.portfolio__home-search img {
  filter: brightness(0) invert(1);
  width: 15px;
}

.work-search {
  position: relative;
}

@media (max-width: 992px) {
  .portfolio__home-list {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }
}

@import "../assets/styles/portfolio-home.css";
</style>