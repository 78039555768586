<template>
  <div class="container">
    <div class="container__slider">
      <CarouselHome />
    </div>
    <div class="content d-flex flex-column">
      <ServicesHome />
    </div>

    <div class="content__values">
      <CarouselValues />
    </div>

    <div v-dragscroll class="content__history">
      <div class="history_container">
        <div class="history">
          <div class="history_text">{{ $t("homepage.history") }}</div>
        </div>
        <div class="history_image">
          <img src="../assets/images/trophy.webp" />
        </div>
        <div v-for="item in history" :key="item.id">
          <img v-if="item.types === 'homepage'" class="custom-w" :src="item.media[0].original_url" />
        </div>
      </div>
    </div>
    <div class="content__portfolio">
      <PortfolioHome />
    </div>
    <div class="content d-flex flex-column">
      <CompanyHome />
    </div>
  </div>
</template>

<script>
import CarouselHome from "@/components/slide/CarouselHome.vue";
import PortfolioHome from "@/components/PortfolioHome.vue";
import CompanyHome from "@/components/CompanyHome.vue";
import ServicesHome from "@/components/ServicesHome.vue";
import CarouselValues from "@/components/slide/CarouselValues.vue";
import Admin from "@/apis/Admin.js";

export default {
  name: "home-page",
  data() {
    return {
      slides: 5,
      title: "HomePage",
      history:[],
    };
  },
  mounted() {  
    this.$nextTick(() => {
     this.getHistory();
    });
  },
   methods: {
      getHistory() {
      Admin.getHistory().then((response) => {
        this.history = response.data;
      });
    },

    
   },
  components: {
    CarouselHome,
    PortfolioHome,
    CompanyHome,
    ServicesHome,
    CarouselValues,
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

.history {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 230px;
}

.history_image {
  position: absolute;
  left: 535px;
  top: 150px;
}

.history_container {
  position: relative;
  text-align: center;
  color: white;
}

.custom-w {
  width: 2960px;
}

.history_text {
  width: 76%;
  text-align: left;
  font-size: 24px;
  font-family: "XMH Flama-Medium";
}

.container {
  width: 100%;
  max-width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.content {
  width: 1171px;
  align-self: center;
}

.content__values {
  margin: 20px 0px 30px 10px;
  width: 1171px;
  align-self: center;
}

.content__services__services-presentation {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.content__history {
  overflow: hidden;
  cursor: url("./../assets/images/drag_img_color.webp") 10 3, auto!important;
  background-color: #606060;
  padding: 75px 0px;
  
}

@import "../assets/styles/homepage.css";
</style>