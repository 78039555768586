import swal from 'sweetalert2'

const Alert = swal.mixin({
  confirmButtonColor: '#3c64b1',
  showConfirmButton: true,
  allowOutsideClick: false,
  allowEscapeKey: false,
})

window.showSuccess = (title, message = null) => {
  Alert.fire({
    icon: 'success',
    title,
    html: message,
  })
}

window.showError = (title, message = null) => {
  Alert.fire({
    icon: 'warning',
    title,
    html: message,
  })
}