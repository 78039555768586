import Api from "@/apis/Api.js";

export default {
    apply (data) {
        return Api.post('/apply', data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
    }
}