<template>
  <div class="catalogue__page">
    <div class="catalogue__page-container">
      <div class="catalogue__page-title">
        <hr class="line-title" />
        <h1>{{ $t("homepage.footer.pages.catalogue") }}</h1>
      </div>
      <div class="catalogue__page-content">
        <div class="catalogue__page-row">
          <div
            class="catalogue__page-item"
            v-for="item in items"
            :key="item.id"
          >
            <div class="item-image">
              <a :href="item.media[1].original_url" target="_blank"
                ><img :src="item.media[0].original_url"
              /></a>
            </div>

            <div class="item-title">
              <a :href="item.media[1].original_url" target="_blank"
                ><h5>{{ item.name }}</h5></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Admin from "@/apis/Admin.js";

export default {
  name: "catalogue-page",
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getCatalogues();
    });
  },
  methods: {
    getCatalogues() {
      Admin.getCatalogues().then((response) => {
        this.items = response.data;
      });
    },
  },
};
</script>

<style scoped>
.catalogue__page {
  width: 100%;
  display: flex;
  justify-content: center;
}

.catalogue__page-container {
  width: 1171px;
  margin: 80px 0px;
}

.catalogue__page-title h1 {
  font-family: "XMH Flama-Medium";
  text-transform: uppercase;
  color: #4d4d4e;
  font-size: 35px;
  margin-bottom: 70px;
}

.line-title {
  width: 65px;
  margin-top: -1px;
  margin-left: 0px;
  opacity: 1;
  border: 2px solid #01aad7;
  background-color: #01aad7;
}

.catalogue__page-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}

.item-image img {
  width: 100%;
  cursor: pointer;
}

.catalogue__page-item {
  margin-bottom: 20px;
  border-bottom: 3px solid #e2e2e2;
}

.item-title a:hover {
  text-decoration: none !important;
}

.item-title h5 {
  font-family: "XMH Flama-Medium";
  font-size: 16px;
  color: #4d4d4e;
  margin: 12px 0px;
  cursor: pointer;
}

@media (max-width: 992px) {
  .catalogue__page-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 0px;
    width: 100%;
  }
  .catalogue__page-container {
    width: 85%;
  }
}

@media (max-width: 720px) {
  .catalogue__page-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 0px;
    width: 100%;
  }
}

@media (max-width: 550px) {
  .catalogue__page-row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 0px;
    width: 100%;
  }
}
</style>