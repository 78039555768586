<template>
  <div class="portfolio_page">
    <div class="portfolio_breadcrumbs">
      <b-breadcrumb :items="translatedItems"></b-breadcrumb>
    </div>
    <div class="page-container">
      <div class="filters-left">
        <form id="portfolio-filters" class="filters_section">
          <div class="filters_container">
            <div class="filters_title">
              <h3>{{ $t("portfolioPage.filter") }}</h3>
            </div>
            <div class="">
              <div class="custom-column-1">
                <div class="filters_item">
                  <h4 class="filt">{{ $t("portfolioPage.categories") }}</h4>
                </div>
                <div class="">
                  <div v-for="filter in filters" :key="filter.id">
                    <div
                      class="filters_item"
                      v-if="filter.type === 'categorii'"
                    >
                      <input
                        :value="filter.name"
                        @click="filtersHandler(filter.name, filter.type)"
                        type="checkbox"
                        :id="'filter-' + filter.id"
                        v-model="filter.checked"
                        checked
                      /><label :for="'filter-' + filter.id" v-if="lang === 'ro'">{{
                        filter.name
                      }}</label>
                      <label :for="'filter-' + filter.id" v-else-if="lang === 'en'">{{
                        filter.name_en
                      }}</label>
                      <label :for="'filter-' + filter.id" v-else-if="lang === 'fr'">{{
                        filter.name_fr
                      }}</label>
                      <label :for="'filter-' + filter.id" v-else-if="lang === 'de'">{{
                        filter.name_de
                      }}</label>
                    </div>
                  </div>
                </div>
                <div class="filters_item">
                  <input
                    @click="toggleAllCategories('categorii')"
                    type="checkbox"
                    id="checkAllcategorii"
                    checked
                  /><label
                    for="checkAllcategorii"
                    id="checkAllcategoriiLabel"
                    >{{ $t("portfolioPage.uncheckAll") }}</label
                  >
                </div>
              </div>
              <hr class="delimitation-services" />

              <div class="">
                <div>
                  <div class="filters_item">
                    <h4 class="filt">{{ $t("portfolioPage.years") }}</h4>
                  </div>
                  <div v-for="filter in filters" :key="filter.id">
                    <div class="filters_item" v-if="filter.type === 'ani'">
                      <input
                        :value="filter.name"
                        @click="filtersHandler(filter.name, filter.type)"
                        type="checkbox"
                        :id="'filter-' + filter.id"
                        v-model="filter.checked"
                        checked
                      />
                     <label :for="'filter-' + filter.id" v-if="lang === 'ro'">{{
                        filter.name
                      }}</label>
                      <label :for="'filter-' + filter.id" v-else-if="lang === 'en'">{{
                        filter.name_en
                      }}</label>
                      <label :for="'filter-' + filter.id" v-else-if="lang === 'fr'">{{
                        filter.name_fr
                      }}</label>
                      <label :for="'filter-' + filter.id" v-else-if="lang === 'de'">{{
                        filter.name_de
                      }}</label>
                    </div>
                  </div>
                  <div class="filters_item">
                    <input
                      @click="toggleAllCategories('ani')"
                      type="checkbox"
                      id="checkAllani"
                      checked
                    /><label for="checkAllani" id="checkAllaniLabel">{{
                      $t("portfolioPage.uncheckAll")
                    }}</label>
                  </div>
                </div>
                <hr class="delimitation-services" />

                <div>
                  <div class="filters_item">
                    <h4 class="filt">{{ $t("portfolioPage.zone") }}</h4>
                  </div>
                  <div v-for="filter in filters" :key="filter.id">
                    <div class="filters_item" v-if="filter.type === 'zone'">
                      <input
                        :value="filter.name"
                        @click="filtersHandler(filter.name, filter.type)"
                        type="checkbox"
                        :id="'filter-' + filter.id"
                        v-model="filter.checked"
                        checked
                      />
                     <label :for="'filter-' + filter.id" v-if="lang === 'ro'">{{
                        filter.name
                      }}</label>
                      <label :for="'filter-' + filter.id" v-else-if="lang === 'en'">{{
                        filter.name_en
                      }}</label>
                      <label :for="'filter-' + filter.id" v-else-if="lang === 'fr'">{{
                        filter.name_fr
                      }}</label>
                      <label :for="'filter-' + filter.id" v-else-if="lang === 'de'">{{
                        filter.name_de
                      }}</label>
                    </div>
                  </div>
                  <div class="filters_item">
                    <input
                      @click="toggleAllCategories('zone')"
                      type="checkbox"
                      id="checkAllzone"
                      checked
                    /><label for="checkAllzone" id="checkAllzoneLabel">{{
                      $t("portfolioPage.uncheckAll")
                    }}</label>
                  </div>
                </div>
              </div>
            </div>
            <hr class="delimitation-services" />
            <div class="filters_row">
              <div class="filters_item">
                <h4 class="filt">{{ $t("portfolioPage.services") }}</h4>
              </div>
              <div class="">
                <div v-for="filter in filters" :key="filter.id">
                  <div class="filters_item" v-if="filter.type === 'servicii'">
                    <input
                      :value="filter.name"
                      @click="filtersHandler(filter.name, filter.type)"
                      type="checkbox"
                      :id="'filter-' + filter.id"
                      v-model="filter.checked"
                      checked
                    />
                      <label :for="'filter-' + filter.id" v-if="lang === 'ro'">{{
                        filter.name
                      }}</label>
                      <label :for="'filter-' + filter.id" v-else-if="lang === 'en'">{{
                        filter.name_en
                      }}</label>
                      <label :for="'filter-' + filter.id" v-else-if="lang === 'fr'">{{
                        filter.name_fr
                      }}</label>
                      <label :for="'filter-' + filter.id" v-else-if="lang === 'de'">{{
                        filter.name_de
                      }}</label>
                  </div>
                </div>
              </div>
              <div class="filters_item">
                <input
                  @click="toggleAllCategories('servicii')"
                  type="checkbox"
                  id="checkAllservicii"
                  checked
                /><label for="checkAllservicii" id="checkAllserviciiLabel">{{
                  $t("portfolioPage.uncheckAll")
                }}</label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="projects-right">
        <div class="google-map_section">
          <div class="google-map_container">
            <l-map style="height: 500px" :zoom="zoom" :center="center">
              <l-tile-layer
                :url="url"
                :attribution="attribution"
              ></l-tile-layer>
              <l-marker
                v-for="(marker, index) in markers"
                :key="index"
                :lat-lng="marker.latlng"
              >
                <l-popup>
                  <a :href="'/portofoliu/' + marker.slug">
                    <span v-html="marker.description"></span>
                    <img :src="marker.image" />
                  </a>
                </l-popup>
              </l-marker>
            </l-map>
          </div>
        </div>
        <div class="projects_section">
          <div class="infinite-scroll-container">
            <div class="project" v-for="project in projects" :key="project.id">
              <div class="project_card-img">
                <a :href="'/portofoliu/' + project.slug">
                  <div class="project__search">
                    <img src="./../../assets/images/search-topbar.webp" />
                  </div>
                  <img
                    :src="project.media[0].original_url"
                    :class="'test-' + project.id"
                  />
                </a>
              </div>
              <div class="overlay_container">
                <div
                  class="images"
                  v-for="image in project.media.filter(img => img.collection_name !== 'main_image')"
                  :key="image.id"
                >
                  <img
                    :src="image.original_url"
                    @mouseover="updateMainImage(image.original_url, project.id)"
                  />
                </div>
              </div>
              <div class="project_card-info">
                <a
                  class="project_card-title"
                  :href="'/portofoliu/' + project.slug"
                  >{{ project.name }}</a
                >
                <a class="project_card-date">{{ project.year }}</a>
              </div>
            </div>
          </div>
        </div>

        <div id="spinner" class="text-center mb-3 spinner">
          <b-spinner
            v-for="variant in variants"
            :variant="variant"
            :key="variant"
          ></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import L from "leaflet";
import Admin from "@/apis/Admin.js";

export default {
  name: "portfolio-page",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },

  data() {
    return {
      users: [],
      filters: [],
      formState: [],
      items: [
        {
          text: "portfolioPage.title",
          active: true,
        },
      ],
      title: "Portofoliu",
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 7,
      center: [45.9432, 24.9668],
      markerLatLng: [45.9432, 24.9668],
      markers: [],
      projects: [],
      page: 1,
      categoriesFilters: [],
      zonesFilters: [],
      yearsFilters: [],
      servicesFilters: [],
      projectsLocations: [],
      variants: ["info"],
      categoriiCheck: true,
      lang: localStorage.getItem("lang") || "ro",
    };
  },
   computed: {
    translatedItems() {
      return this.items.map(item => {
        return {
          text: this.$t(item.text),
          active: item.active
        }
      })
    }
  },
  beforeMount() {
    this.getInitialProjects();
  },

  mounted() {
    this.getFilters();
    this.getNextProject();
  },

  methods: {
    updateMainImage(src, id) {
      const mainImage = document.querySelector(".test-" + id);
      mainImage.src = src;
    },
    toggleAllCategories(type) {
      document.getElementById("spinner").classList.remove("d-none");

      if (type === "categorii") {
        const selectAllCheckbox = document.getElementById(`checkAllcategorii`);
        const label = document.getElementById(`checkAllcategoriiLabel`);
        const checked = selectAllCheckbox.checked;
        //categorii
        const categoriiFilters = this.filters.filter(
          (filter) => filter.type === "categorii"
        );
        const categoriiFilterNames = categoriiFilters.map(
          (filter) => filter.name
        );
        categoriiFilters.forEach((filter) => {
          filter.checked = checked;
        });
        if (!selectAllCheckbox.checked) {
          label.innerHTML = this.$t("portfolioPage.checkAll");
          this.categoriesFilters = categoriiFilterNames;
          categoriiFilters.forEach((filter) => {
            this.$set(
              this.filters.find((f) => f.id === filter.id),
              "checked",
              false
            );
          });
        } else {
          label.innerHTML = this.$t("portfolioPage.uncheckAll");
          this.categoriesFilters = [];
          categoriiFilters.forEach((filter) => {
            this.$set(
              this.filters.find((f) => f.id === filter.id),
              "checked",
              true
            );
          });
        }
      }

      if (type === "ani") {
        const selectAllCheckbox = document.getElementById(`checkAllani`);
        const label = document.getElementById(`checkAllaniLabel`);
        const checked = selectAllCheckbox.checked;
        //ani
        const aniFilters = this.filters.filter(
          (filter) => filter.type === "ani"
        );
        const aniFilterNames = aniFilters.map((filter) => filter.name);
        aniFilters.forEach((filter) => {
          filter.checked = checked;
        });
        if (!selectAllCheckbox.checked) {
          label.innerHTML = this.$t("portfolioPage.checkAll");
          this.yearsFilters = aniFilterNames;
          aniFilters.forEach((filter) => {
            this.$set(
              this.filters.find((f) => f.id === filter.id),
              "checked",
              false
            );
          });
        } else {
          label.innerHTML = this.$t("portfolioPage.uncheckAll");
          this.yearsFilters = [];
          aniFilters.forEach((filter) => {
            this.$set(
              this.filters.find((f) => f.id === filter.id),
              "checked",
              true
            );
          });
        }
      }

      if (type === "zone") {
        const selectAllCheckbox = document.getElementById(`checkAllzone`);
        const label = document.getElementById(`checkAllzoneLabel`);
        const checked = selectAllCheckbox.checked;

        //zone
        const zoneFilters = this.filters.filter(
          (filter) => filter.type === "zone"
        );
        const zoneFilterNames = zoneFilters.map((filter) => filter.name);
        zoneFilters.forEach((filter) => {
          filter.checked = checked;
        });
        if (!selectAllCheckbox.checked) {
          label.innerHTML = this.$t("portfolioPage.checkAll");
          this.zonesFilters = zoneFilterNames;
          zoneFilters.forEach((filter) => {
            this.$set(
              this.filters.find((f) => f.id === filter.id),
              "checked",
              false
            );
          });
        } else {
          label.innerHTML = this.$t("portfolioPage.uncheckAll");
          this.zonesFilters = [];
          zoneFilters.forEach((filter) => {
            this.$set(
              this.filters.find((f) => f.id === filter.id),
              "checked",
              true
            );
          });
        }
      }

      if (type === "servicii") {
        const selectAllCheckbox = document.getElementById(`checkAllservicii`);
        const label = document.getElementById(`checkAllserviciiLabel`);
        const checked = selectAllCheckbox.checked;

        //servicii
        const serviciiFilters = this.filters.filter(
          (filter) => filter.type === "servicii"
        );
        const serviciiFilterNames = serviciiFilters.map(
          (filter) => filter.name
        );

        serviciiFilters.forEach((filter) => {
          filter.checked = checked;
        });
        if (!selectAllCheckbox.checked) {
          label.innerHTML = this.$t("portfolioPage.checkAll");
          this.servicesFilters = serviciiFilterNames;
          serviciiFilters.forEach((filter) => {
            this.$set(
              this.filters.find((f) => f.id === filter.id),
              "checked",
              false
            );
          });
        } else {
          label.innerHTML = this.$t("portfolioPage.uncheckAll");
          this.servicesFilters = [];
          serviciiFilters.forEach((filter) => {
            this.$set(
              this.filters.find((f) => f.id === filter.id),
              "checked",
              true
            );
          });
        }
      }

      this.projects = [];
      this.markers = [];
      this.getInitialProjects(
        this.categoriesFilters,
        this.zonesFilters,
        this.yearsFilters,
        this.servicesFilters
      );
    },

    getFilters() {
      Admin.getFilters().then((response) => {
        this.filters = response.data;
      });
    },

    getInitialProjects(
      categories = null,
      zones = null,
      years = null,
      services = null
    ) {
      this.next_page = true;
      Admin.getProjects(this.page, categories, zones, years, services).then(
        (response) => {
          this.projects = response.data.portofolios.data;
          response.data.portofoliosLatLng.forEach((collectionPoint) => {
            this.markers.push({
              latlng: L.latLng(
                collectionPoint.location.lat,
                collectionPoint.location.lng
              ),
              description: collectionPoint.name,
              image: collectionPoint.media[0].original_url,
              slug: collectionPoint.slug,
            });
          });
        }
      );
    },

    getNextProject() {
      window.onscroll = () => {
        let section = document.getElementById("spinner");
        let bottomOfSection = section.offsetTop + section.offsetHeight;
        let bottomOfWindow = window.innerHeight + window.pageYOffset;
        let reachedSection = bottomOfWindow >= bottomOfSection;
        if (reachedSection && this.next_page && !this.isLoading) {
          this.isLoading = true;
          this.page++;
          Admin.getProjects(
            this.page,
            this.categoriesFilters,
            this.zonesFilters,
            this.yearsFilters,
            this.servicesFilters
          ).then((response) => {
            if (!response.data.portofolios.next_page_url) {
              this.next_page = false;
              document.getElementById("spinner").classList.add("d-none");
            }
            response.data.portofolios.data.forEach((project) => {
              this.projects.push(project);
            });
            this.isLoading = false;
          });
        }
        if (!this.next_page) {
          this.page = 1;
        }
      };
    },

    filtersHandler(filter, type) {
      document.getElementById("spinner").classList.remove("d-none");

      if (type === "ani") {
        this.yearsFilters.indexOf(filter) !== -1
          ? (this.yearsFilters = this.yearsFilters.filter(
              (el) => el !== filter
            ))
          : this.yearsFilters.push(filter);

      }

      if (type === "zone") {
        this.zonesFilters.indexOf(filter) !== -1
          ? (this.zonesFilters = this.zonesFilters.filter(
              (el) => el !== filter
            ))
          : this.zonesFilters.push(filter);
      }

      if (type === "categorii") {
        this.categoriesFilters.indexOf(filter) !== -1
          ? (this.categoriesFilters = this.categoriesFilters.filter(
              (el) => el !== filter
            ))
          : this.categoriesFilters.push(filter);
      }

      if (type === "servicii") {
        this.servicesFilters.indexOf(filter) !== -1
          ? (this.servicesFilters = this.servicesFilters.filter(
              (el) => el !== filter
            ))
          : this.servicesFilters.push(filter);
      }

      this.projects = [];
      this.markers = [];

      this.getInitialProjects(
        this.categoriesFilters,
        this.zonesFilters,
        this.yearsFilters,
        this.servicesFilters
      );
    },
  },
};
</script>

<style scoped>
* {
  font-family: "XMH Flama", monospace;
}
.load-more-projects {
  display: flex;
  justify-content: center;
}

button#load-more {
  background-color: rgb(0, 170, 214);
  text-transform: uppercase;
  width: 190px;
  height: 45px;
  font-size: 12px;
  border: 1px;
  color: #ffffff;
  font-family: "XMH Flama-Basic";
  letter-spacing: 1.56px;
  margin: 30px 0px;
  padding-top: 3px;
}

.project__search {
  background-color: #00aad6;
  padding: 24px 22px 5px 9px;
  position: absolute;
  bottom: 8%;
  right: 4%;
  z-index: 999;
}

.project__search img {
  filter: brightness(0) invert(1);
  width: 15px !important;
  height: auto !important;
  -o-object-fit: initial !important;
  top: 8px !important;
}

.projects_section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.portfolio_breadcrumbs {
  height: 206px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: url(./../../assets/images/portfolio/breadcrumbs-port.webp);
}

.portfolio_breadcrumbs .breadcrumb {
  background: transparent;
}

.google-map_section {
  width: 100%;
  display: flex;
  justify-content: center;
}

.google-map_section img {
  width: 100%;
  margin-top: 50px;
  object-fit: cover;
}

.google-map_container {
  width: 85%;
  margin-top: 30px;
}
.leaflet-container a {
  color: black !important;
  text-decoration: none;
}

.portfolio_page {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.breadcrumb {
  width: 1171px;
  padding: 0.75rem 0rem !important;
}

.breadcrumb span {
  font-size: 29px;
  text-transform: uppercase;
  color: white;
  color: #fffefe;
  font-family: "XMH Flama-Basic";
}

ol.breadcrumb {
  padding: 0px !important;
  margin-bottom: 40px;
}

.filters_section {
  background: #00aad6;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: white;
  height: 100%;
}

.filters_container {
  width: 100%;
  background:#00aad6;
  padding-left:25px!important;
  display: flex;
  padding: 60px 0px;
  flex-wrap: wrap;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.filters_title {
  flex: 0 0 100%;
}

.filters_title h3 {
  text-transform: uppercase;
  font-size: 23px;
  margin-bottom: 1.5rem;
  color: #ffffff;
  font-family: "XMH Flama-Basic";
}

.filters_item {
  white-space: nowrap;
  color: black;
  word-spacing: -3px;
  display: flex;
  align-items: center;
}

.filt {
  font-weight: bold;
  color: white;
  font-size: 16px;
  margin-bottom: 11px !important;
}

.delimitation-services {
  width: 100%;
  height: 2px;
  border: none;
  background: #0099c1;
  margin: 12px 0px;
  flex: 0 0 100%;
}

.filters_item label {
  margin-left: 10px;
  margin-bottom: 0px !important;
  font-size: 16px;
  color: #252525;
  font-family: "XMH Flama-Book";
  line-height: 29px;
  word-spacing: 1px;
}

.navigation__footer {
  display: none !important;
}

.filters_column-3,
.filters_column-4 {
  margin-top: -25px;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 13px;
  height: 13px;
  /* transform: translateY(-0.075em); */
  display: flex;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: CanvasText;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  margin-top: 1px;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

/* infinite scroll */
.infinite-scroll-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  width: 85%;
}

.project {
  display: flex;
  border-radius: 1em;
  margin: 5px 0px;
  flex-direction: column;
  width: 100%;
}

.project_card-img {
  position: relative;
  padding-top: 56.25%;
}

.project_card-img img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  top: 0;
}

.project_card-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0px;
}

.project_card-info a {
  text-decoration: none !important;
}

.project-name {
  margin: 0;
  padding: 0;
  font-size: 2rem;
  font-weight: 900;
}

.project_card-title {
  margin-right: 15px;
  color: #252525;
  font-size: 17px;
  line-height: 20px;
  font-family: "XMH Flama-Medium";
  letter-spacing: -0.6px;
}

.project_card-date {
  font-family: "XMH Flama-Book";
  font-size: 16px;
  letter-spacing: -0.55px;
  color: #252525;
}

.row-1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.row-2 {
  column-width: 840px;
}

.c-col-1 {
  column-width: 780px;
}

.c-custom-col {
  column-width: 340px;
  column-gap: 73px;
}

.c-col-2 {
  display: flex;
  justify-content: space-between;
  width: 360px;
}

.c-col-3 {
  column-width: 370px;
}

@media (max-width: 1366px) {
  .filters_container,
  .google-map_container,
  .infinite-scroll-container {
    width: 85%;
  }
}

@media (max-width: 1340px) {
  .c-col-2 {
    width: 330px;
  }
}

@media (max-width: 1280px) {
  .c-col-3 {
    column-width: 360px;
  }
  .filters_item label {
    font-size: 13px;
    line-height: 21px;
    margin-left: 6px;
  }
  .row-2 {
    column-width: 823px;
  }
}

@media (max-width: 1261px) {
  .c-col-1 {
    column-width: 650px;
  }
  .c-custom-col {
    column-width: 200px;
  }
  .row-2 {
    column-width: 711px;
  }
  .c-col-3 {
    column-width: 250px;
  }
  .c-col-2 {
    width: 305px;
  }
}

@media (max-width: 1120px) {
  .row-1 {
    /* width: 100%; */
    display: flex;
    justify-content: flex-start;
  }
  .c-custom-col {
    column-width: 363px;
  }
  .c-col-1 {
    column-width: 200px;
  }
}

@media (max-width: 850px) {
  .infinite-scroll-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 30px;
  }
  .project {
    width: 100%;
  }
  .c-col-1 {
    width: 100%;
  }
  .c-col-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
  .row-2 {
    column-count: 1;
    column-width: 708px;
  }
}

@media (max-width: 767px) {
  .row-1 {
    flex-direction: column;
  }
  .c-col-2 {
    margin-top: 15px;
    row-gap: 15px;
  }
  .delimitation-services {
    margin: 20px 0px;
  }
  .google-map_container iframe {
    height: 300px;
  }
  .c-col-1,
  .c-col-3 {
    column-width: 600px;
  }
}

@media (max-width: 480px) {
  .infinite-scroll-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
  }
}

@media (max-width: 350px) {
  .filters_item {
    white-space: break-spaces;
  }
}
@media (min-width: 920px) {
  .page-container {
    display: flex;
  }
  .filters-left {
    width: 25%;
  }
  .projects-right {
    width: 75%;
  }
  .filters_item {
    white-space: normal;
  }
}
.overlay_container {
  display: flex;
  flex-wrap: wrap;
}
.images {
  padding: 5px;
}
.images img {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 20px;
}
</style>