<template>
  <div class="portfolio__work">
    <div
      v-if="breadcrumb_image"
      class="portfolio__work-breadcrumbs"
      :style="{ backgroundImage: `url('${breadcrumb_image}')` }"
    >
      <b-breadcrumb style="width: 65%" :items="items"></b-breadcrumb>
    </div>
    <div v-else class="portfolio__work-breadcrumbs">
      <b-breadcrumb style="width: 65%" :items="items"></b-breadcrumb>
    </div>

    <div class="portfolio__work-container">
      <div class="portfolio__work-content">
        <div class="portfolio__work-services-left-column">
          <div class="portfolio__work-title">
            <hr class="work-title" />
            <h1>{{ work.name }}</h1>
            <div class="portfolio__work-title"><p><strong>{{$t("portfolioWork.client")}}</strong> {{work.client}}</p></div>
            <div class="portfolio__work-title"><p><strong>{{$t("portfolioWork.year")}}</strong> {{work.year}}</p></div>
            <div v-html="work.content"></div>
            <div class="portfolio__work-title"><p><strong>{{$t("portfolioWork.execution_time")}}</strong> {{work.execution_time}}</p></div>
            <div class="portfolio__work-title"><p><strong>{{$t("portfolioWork.surface")}}</strong> {{work.surface}}</p></div>
            <div class="portfolio__work-title"><p><strong>{{$t("portfolioWork.executed_work")}}</strong></p>
              <li v-for="{name} in work.location?.services" :key="name">{{name}} </li>
            </div>
          </div>
        </div>
        <div class="portfolio__work-services-right-column"></div>
      </div>
    </div>

    <hr class="delimitation-work" />

    <CoolLightBox
      :items="lightBoxItems"
      :index="index"
      :effect="'swipe'"
      loop
      @close="index = null"
    >
    </CoolLightBox>
    <div class="portfolio__work-gallery w-100 d-flex justify-content-center">
      <div class="portfolio__work-gallery-list">
        <div
          class="portfolio__work-img"
          v-for="(image, imageIndex) in filteredMedia"
          :key="imageIndex"
          @click="index = imageIndex"

        >
          <img  :src="image.original_url" />

        </div>
      </div>
    </div>
    <hr class="delimitation-work" />
      <div class="portfolio__work-back">
        <div class="portfolio__work-content">
          <div class="">
          <button class="buttonBg" @click="$router.go(-1)">{{$t("portfolioWork.back")}}</button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Admin from "@/apis/Admin";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
export default {
  name: "portfolio-work",
  components: {
    CoolLightBox,
  },
  data() {
    return {
      work: [],
      lightBoxItems: [],
      index: null,
      breadcrumb_image: "",
      items: [
        {
          text: this.$t("portfolioPage.title"),
          href: "/portofoliu",
        },
        {
          text: "Categorie",
          href: "/portofoliu",
        },
        {
          text: "Lucrare",
          href: "/",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getSingleProject(this.$router.history.current.params.slug);
    });
  },
   computed: {
    filteredMedia() {
      return this.work.media?.filter(image => image.collection_name === 'portofolio_images');
    }
  },
  methods: {
    getSingleProject(slug) {
      Admin.getSingleProject(slug).then((response) => {
        this.work = response.data;
        this.work.media.forEach((item) => {
          this.lightBoxItems.push({
            src: item.original_url,
            mediaType: "image",
          });

          if (item.collection_name === "main_image") {
            this.breadcrumb_image = item.original_url;
          }
        });
        this.items = [
          {
            text: this.$t("portfolioPage.title"),
            href: "/portofoliu",
          },
          {
            text: this.work.location?.category_lang.join(),
            href: "/portofoliu",
          },
          {
            text: this.work.location?.services.map(({name}) => ' '+name),
            href: "/",
          },
        ];
      });
    },
  },
};
</script>

<style>
.portfolio__work-back{
   width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom:20px;
}
.buttonBg {
    background-color: #252525;
    width: 215px;
    height: 53px;
    border: 1px solid #252525;
    color: #ffffff;
    font-family: "OpenSans-Regular";
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.3px;
}
.portfolio__work-breadcrumbs {
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(./../../assets/images/portfolio/palas-showroom.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  background-color: rgba(0,0,0,0.3);

}

.portfolio__work-breadcrumbs .breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  line-height: 30px;
}

.portfolio__work-breadcrumbs .breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  padding: 0;
}

.portfolio__work-breadcrumbs a {
  text-decoration: none !important;
}

.breadcrumb-item a {
  font-size: 27px;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
  font-family: "XMH Flama-Basic";
}

.breadcrumb-item + .breadcrumb-item::before {
  color: white;
  font-size: 30px;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
}

.portfolio__work .breadcrumb span {
  font-size: 25px;
  text-transform: uppercase;
  color: white;
  font-family: "XMH Flama-Basic";
  margin-bottom: 0px;
}

.portfolio__work {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.portfolio__work-img img {
  cursor: pointer;
}

.portfolio__work-content {
  width: 1171px;

}

.portfolio__work-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.portfolio__work-gallery-list {
  width: 1171px;
  --gap: 30px;
  --num-cols: 3;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  gap: var(--gap);
}

.portfolio__work-gallery-list img {
  width: 100%;
  height: 220px;
  object-fit: cover;
}

.work-title {
  background-color: #00aad7;
  width: 70px;
  height: 3px;
  margin: 0;
  border: none;
}

.portfolio__work-gallery {
  width: 1171px;
}

.delimitation-work {
  background-color: #e2e2e2;
  width: 1171px;
  height: 3px;
  border: none;
  margin-top: 50px;
  margin-bottom: 50px;
}

.portfolio__work-title h1 {
  text-transform: uppercase !important;
  color: #4d4d4e;
  font-family: "XMH Flama-Medium";
  font-size: 35px;
  margin: 10px 0px 30px 0px;
}

.portfolio__work-title h5 {
  font-family: "XMH Flama-Medium";
  color: #4d4d4e;
  font-size: 20px;
}

.portfolio__work-title p {
  color: #4d4d4e;
  margin-bottom: 3px;
  font-size: 18px;
}

.portfolio__work-services-right-column p {
  color: #4d4d4e;
  font-size: 20px;
}

@media (max-width: 1366px) {
  .portfolio__work-content,
  .delimitation-work,
  .portfolio__work-gallery-list {
    width: 85%;
  }
}

@media (max-width: 992px) {
  .breadcrumb-item a {
    font-size: 24px;
  }
  .portfolio__work-title h1 {
    font-size: 28px;
  }
  .portfolio__work-title p {
    font-size: 16px;
  }
  .portfolio__work-gallery-list {
    --num-cols: 2;
  }
  .portfolio__work-img {
    height: 100% !important;
  }
}

@media (max-width: 767px) {
  .portfolio__work-gallery-list {
    --num-cols: 1;
  }
  .portfolio__work-gallery-list img {
    height: auto;
  }
}
</style>
