<template>
  <div class="ag__partnership-page">
    <div class="ag__partnership-breadcrumbs">
      <b-breadcrumb :items="items"></b-breadcrumb>
    </div>
    <div class="ag__partnership-content">
      <div class="ag__partnership-services">
        <div class="ag__partnership-services-left-column">
          <div class="ag__partnership-title">
            <h1 class="line-after">
              {{ $t("homepage.header.menuValues.services") }}
            </h1>
          </div>
          <div class="ag__partnership-subtitle">
            <h2>{{ service.name }}</h2>
          </div>
          <div class="ag__partnership-text" v-html="service.content"></div>
        </div>
        <div class="ag__partnership-services-right-column">
          <div class="ag__partnership-services-image">
            <img :src="mainImage" />
            <figcaption class="ag__partnership-figcaption">
              {{ service.image_title }}
            </figcaption>
          </div>
        </div>
      </div>
    </div>

    <AgPartnershipLightbox
      v-if="
        window.location.href ==
        window.location.origin +
          '/cautare/serviciu/ag-in-parteneriat-cu-marii-antreprenori'
      "
    />
    <MaintenancePageLightbox
      v-if="
        window.location.href ==
        window.location.origin + '/cautare//serviciu/mentenanta'
      "
    />
    <ProjectManagementLightbox
      v-if="
        window.location.href ==
        window.location.origin + '/cautare/serviciu/management-de-proiect'
      "
    />
    <IndustrialWallsLightbox
      v-if="
        window.location.href ==
        window.location.origin +
          '/cautare/serviciu/montaj-pereti-si-acoperisuri-industriale'
      "
    />
    <LargeDoorsLightbox
      v-if="
        window.location.href ==
        window.location.origin + '/cautare/serviciu/portiere-de-mari-dimensiuni'
      "
    />
    <AssemblyStructuresLightbox
      v-if="
        window.location.href ===
        window.location.origin +
          '/cautare/serviciu/montaj-structuri-metalice-sau-prefabricate-din-beton'
      "
    />

    <div
      class="ag__partnership-more-services w-100 d-flex justify-content-center"
    >
      <div class="other__services-list">
        <div>
          <h1 class="line-after other__services-title">
            <span>{{ $t("homepage.anotherServ") }}</span>
          </h1>
        </div>
        <div class="other__services-item">
          <div
            class="other__services-description"
            v-for="item in randomServices"
            :key="item.id"
          >
            <a :href="item.slug">
              <img class="w-100" :src="item.media[0].original_url" />
              <h5 class="other__services-h5">{{ item.name }}</h5>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgPartnershipLightbox from "@/components/AgPartnershipLightbox.vue";
import ProjectManagementLightbox from "@/components/ProjectManagementLightbox.vue";
import MaintenancePageLightbox from "@/components/MaintenancePageLightbox.vue";
import IndustrialWallsLightbox from "@/components/IndustrialWallsLightbox.vue";
import AssemblyStructuresLightbox from "@/components/AssemblyStructuresLightbox.vue";
import LargeDoorsLightbox from "@/components/LargeDoorsLightbox.vue";

import Admin from "@/apis/Admin.js";

export default {
  components: {
    AgPartnershipLightbox,
    ProjectManagementLightbox,
    IndustrialWallsLightbox,
    MaintenancePageLightbox,
    AssemblyStructuresLightbox,
    LargeDoorsLightbox,
  },
  name: "ag-partnership",
  props: ["slug"],
  data() {
    return {
      items: [],
      service: [],
      window: window,
      mainImage: "",
      randomServices: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getService();
    });
  },
  methods: {
    getService() {
      Admin.getService(this.slug).then((response) => {
        this.service = response.data.service;
        this.randomServices = response.data.random_services;
        this.service.media.forEach((media) => {
          if (media.collection_name === "main_image") {
            this.mainImage = media.original_url;
          }
        });
      });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.breadcrumb {
  background: transparent;
}

.ag__partnership-breadcrumbs {
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: url(./../../../assets/images/servicii/ag/bread-ag.webp);
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center;
}

.breadcrumb span {
  font-size: 27px;
  text-transform: uppercase;
  font-weight: 500;
}

.ag__partnership-page {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ag__partnership-services {
  width: 1171px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 22px;
  grid-row-gap: 0px;
}

.ag__partnership-content {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 60px 0px 30px 0px;
}

.ag__partnership-services-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-top: 6px;
}

.ag__partnership-title h1 {
  color: #252525;
  font-family: "XMH Flama-Medium";
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 30px !important;
  letter-spacing: -1px;
}

.ag__partnership-subtitle h2 {
  font-family: "XMH Flama-Medium";
  width: 310px;
  color: #14afda;
  margin: 70px 0px 30px 0px;
  font-size: 36px;
  line-height: 40.93px;
  letter-spacing: -1.5px;
}

#custom-header {
  font-size: 17px;
  font-weight: 600;
  margin-top: 22px;
}

.ag__partnership-text {
  width: 98%;
}

.ag__partnership-text--margin-top {
  margin-top: 110px;
}

.ag__partnership-text h5 {
  font-family: "XMH Flama-Medium";
  color: #424242;
  font-size: 17px;
  margin-bottom: 30px;
}

.ag__partnership-text p {
  font-family: "OpenSans-Regular";
  font-size: 15px;
  letter-spacing: -0.38px;
  line-height: 23px;
}

.ag__partnership-figcaption {
  font-family: "OpenSans-Regular";
  width: auto;
  height: 66px;
  background-color: #e6e7e2;
  color: #72726f;
  padding-left: 41px;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.ag__partnership-gallery {
  margin: 30px 0px;
}

.ag__partnership-gallery-list {
  width: 1171px;
  --gap: 30px;
  --num-cols: 3;
  --row-height: 200px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  gap: var(--gap);
  margin-bottom: 80px;
}

.ag__partnership-gallery-list-mobile {
  width: 1171px;
  --gap: 30px;
  --num-cols: 1;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  gap: var(--gap);
  margin-bottom: 50px;
}

.ag__partnership-gallery-list-mobile img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-grid-col-2 {
  grid-column: span 2;
}

.image-grid-col-3 {
  grid-column: span 3;
}

.ag__partnership-gallery-list img {
  width: 100%;
}

.ag__partnership-services-right-column {
  margin-top: 13px;
}

.ag__partnership-more-services {
  background-color: #e6e7e2;
}

.other__services-title {
  color: #252525;
  font-family: "XMH Flama-Medium";
  font-size: 24px;
  line-height: 75.79px;
  text-transform: uppercase;
}

.other__services-list {
  width: 1171px;
  padding: 75px 0px;
}

.other__services-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 0px;
}
.other__services-description img {
  min-height: 294px;
}
.other__services-h5 {
  color: #252525;
  font-family: "XMH Flama-Medium";
  font-size: 22px;
  margin: 15px 0px;
  letter-spacing: -0.46px;
}

@media (max-width: 992px) {
  .ag__partnership-services-right-column {
    display: flex;
    flex-direction: column-reverse;
  }
  .ag__partnership-text--margin-top {
    margin-top: 0;
  }
  .ag__partnership-text {
    width: 100%;
  }
  .ag__partnership-breadcrumbs {
    background-repeat: no-repeat;
    background-size: contain;
    height: 224px;
  }
  .ag__partnership-subtitle h2 {
    width: 100%;
  }
  .ag__partnership-services {
    width: 85%;
    display: flex;
    flex-direction: column-reverse;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 50px;
  }
  .other__services-list {
    width: 85%;
  }
}

@media (max-width: 800px) {
  .ag__partnership-breadcrumbs {
    height: 181px;
  }
}

@media (max-width: 750px) {
  .other__services-item {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 40px;
  }
  .ag__partnership-content {
    margin-top: 30px;
  }
}

@media (max-width: 600px) {
  .other__services-h5 {
    font-size: 16px;
  }
  .other__services-item {
    grid-row-gap: 0px;
  }
  .ag__partnership-breadcrumbs {
    height: 136px;
  }
  .ag__partnership-title h1 {
    margin-bottom: 50px;
  }
  .ag__partnership-subtitle h2 {
    margin-top: 40px;
  }
}

@media (max-width: 470px) {
  .ag__partnership-breadcrumbs {
    height: 107px;
  }
  .ag__partnership-subtitle h2 {
    font-size: 25px;
  }
}

@media (max-width: 370px) {
  .ag__partnership-breadcrumbs {
    height: 84px;
  }
}
</style>