var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ag__partnership-page"},[_c('div',{staticClass:"ag__partnership-breadcrumbs"},[_c('b-breadcrumb',{attrs:{"items":_vm.items}})],1),_c('div',{staticClass:"ag__partnership-content"},[_c('div',{staticClass:"ag__partnership-services"},[_c('div',{staticClass:"ag__partnership-services-left-column"},[_c('div',{staticClass:"ag__partnership-title"},[_c('h1',{staticClass:"line-after"},[_vm._v(" "+_vm._s(_vm.$t("homepage.header.menuValues.services"))+" ")])]),_c('div',{staticClass:"ag__partnership-subtitle"},[_c('h2',[_vm._v(_vm._s(_vm.service.name))])]),_c('div',{staticClass:"ag__partnership-text",domProps:{"innerHTML":_vm._s(_vm.service.content)}})]),_c('div',{staticClass:"ag__partnership-services-right-column"},[_c('div',{staticClass:"ag__partnership-services-image"},[_c('img',{attrs:{"src":_vm.mainImage}}),_c('figcaption',{staticClass:"ag__partnership-figcaption"},[_vm._v(" "+_vm._s(_vm.service.image_title)+" ")])])])])]),(
      _vm.window.location.href ==
      _vm.window.location.origin +
        '/cautare/serviciu/ag-in-parteneriat-cu-marii-antreprenori'
    )?_c('AgPartnershipLightbox'):_vm._e(),(
      _vm.window.location.href ==
      _vm.window.location.origin + '/cautare//serviciu/mentenanta'
    )?_c('MaintenancePageLightbox'):_vm._e(),(
      _vm.window.location.href ==
      _vm.window.location.origin + '/cautare/serviciu/management-de-proiect'
    )?_c('ProjectManagementLightbox'):_vm._e(),(
      _vm.window.location.href ==
      _vm.window.location.origin +
        '/cautare/serviciu/montaj-pereti-si-acoperisuri-industriale'
    )?_c('IndustrialWallsLightbox'):_vm._e(),(
      _vm.window.location.href ==
      _vm.window.location.origin + '/cautare/serviciu/portiere-de-mari-dimensiuni'
    )?_c('LargeDoorsLightbox'):_vm._e(),(
      _vm.window.location.href ===
      _vm.window.location.origin +
        '/cautare/serviciu/montaj-structuri-metalice-sau-prefabricate-din-beton'
    )?_c('AssemblyStructuresLightbox'):_vm._e(),_c('div',{staticClass:"ag__partnership-more-services w-100 d-flex justify-content-center"},[_c('div',{staticClass:"other__services-list"},[_c('div',[_c('h1',{staticClass:"line-after other__services-title"},[_c('span',[_vm._v(_vm._s(_vm.$t("homepage.anotherServ")))])])]),_c('div',{staticClass:"other__services-item"},_vm._l((_vm.randomServices),function(item){return _c('div',{key:item.id,staticClass:"other__services-description"},[_c('a',{attrs:{"href":item.slug}},[_c('img',{staticClass:"w-100",attrs:{"src":item.media[0].original_url}}),_c('h5',{staticClass:"other__services-h5"},[_vm._v(_vm._s(item.name))])])])}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }