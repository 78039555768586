<template>
  <search-single-service
    v-if="types[type] === 'service'"
    :slug="slug"
  >
  </search-single-service>
  <search-single-project
    v-else-if="types[type] === 'portofolio'"
    :slug="slug"
  >
  </search-single-project>
</template>

<script>
import SearchSingleService from './search/SearchSingleService.vue';
import SearchSingleProject from './search/SearchSingleProject.vue';

export default {
  name: "portfolio-work",
  components: {
    SearchSingleService,
    SearchSingleProject
  },

  data() {
    return {
      slug: this.$router.history.current.params.slug,
      type: '',
      types: {
        'serviciu': 'service',
        'portofoliu': 'portofolio'
      }
    }
  },

  watch: {
    slug: function(value) {
      console.log(value);
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.showContentSearch();
    });
  },

  methods: {
    showContentSearch() {
      window.location.refresh;
      this.slug = this.$router.history.current.params.slug;
      this.type = this.$router.history.current.params.tip;

      console.log(this.type);
    }
  }
};
</script>

<style scoped>
.portfolio__work-breadcrumbs {
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(./../../assets/images/portfolio/palas-showroom.webp);
  background-size: cover;
  background-repeat: no-repeat;
}

.portfolio__work-breadcrumbs .breadcrumb {
  background: transparent;
  margin-bottom: 0px;
}

.portfolio__work-breadcrumbs .breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  padding: 0;
}

.breadcrumb-item a {
  font-size: 27px;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
  font-family: "XMH Flama-Basic";
}

.breadcrumb-item + .breadcrumb-item::before {
  color: white;
  font-size: 30px;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
}

.portfolio__work .breadcrumb span {
  font-size: 25px;
  text-transform: uppercase;
  color: white;
  font-family: "XMH Flama-Basic";
  margin-bottom: 0px;
}

.portfolio__work {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.portfolio__work-img img {
  cursor: pointer;
}

.portfolio__work-content {
  width: 1171px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 0px;
}

.portfolio__work-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.portfolio__work-gallery-list {
  width: 1171px;
  --gap: 30px;
  --num-cols: 3;
  --row-height: 200px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  gap: var(--gap);
}

.image-grid-col-3 {
  grid-column: span 3;
}

.portfolio__work-gallery-list img {
  width: 100%;
  height: 220px;
}

.work-title {
  background-color: #00aad7;
  width: 70px;
  height: 3px;
  margin: 0;
  border: none;
}

.portfolio__work-gallery {
  width: 1171px;
}

.delimitation-work {
  background-color: #e2e2e2;
  width: 1171px;
  height: 3px;
  border: none;
  margin-top: 50px;
  margin-bottom: 50px;
}

.portfolio__work-title h1 {
  text-transform: uppercase !important;
  color: #4d4d4e;
  font-family: "XMH Flama-Medium";
  font-size: 35px;
  margin: 10px 0px 30px 0px;
}

.portfolio__work-title h5 {
  font-family: "XMH Flama-Medium";
  color: #4d4d4e;
  font-size: 20px;
}

.portfolio__work-title p {
  color: #4d4d4e;
  margin-bottom: 3px;
  font-size: 18px;
}

.portfolio__work-services-right-column p {
  color: #4d4d4e;
  font-size: 20px;
}

@media (max-width: 1366px) {
  .portfolio__work-content,
  .delimitation-work,
  .portfolio__work-gallery-list {
    width: 85%;
  }
}
@media (max-width: 992px) {
  .breadcrumb-item a {
    font-size: 24px;
  }
  .portfolio__work-title h1 {
    font-size: 28px;
  }
  .portfolio__work-title p {
    font-size: 16px;
  }
  .portfolio__work-gallery-list img {
    height: 180px;
  }
}
</style>