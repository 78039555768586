var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content__services d-flex flex-column"},[_c('div',[_c('h1',{staticClass:"content__services-title"},[_vm._v(" "+_vm._s(_vm.$t("homepage.header.menuValues.services"))+" ")])]),_c('div',{staticClass:"content__services__services-presentation"},_vm._l((_vm.services),function(service){return _c('div',{key:service.id,staticClass:"content__services__description"},[_c('hr',{staticStyle:{"margin-bottom":"13px","border-top":"2px solid rgba(0, 0, 0, 0.1"}}),_c('h5',{staticClass:"content__services__header text-uppercase",class:service.vue_components},[_c('a',{attrs:{"href":'/' +
            _vm.serviceLangs[_vm.lang] +
            '/' +
            service.slug +
            '&component=' +
            service.vue_components}},[_vm._v(" "+_vm._s(service.name)+" ")])]),_c('a',{attrs:{"href":'/' +
          _vm.serviceLangs[_vm.lang] +
          '/' +
          service.slug +
          '&component=' +
          service.vue_components}},_vm._l((service.media),function(media){return _c('div',{key:media.id},[(media.collection_name === 'right_image')?_c('img',{staticClass:"mw-100",attrs:{"src":media.original_url}}):_vm._e()])}),0)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }