<template>
  <div class="certifications__page">
    <div class="certifications__page-container">
      <div class="certifications__page-title">
        <hr class="line-title" />
        <h1>{{ $t("homepage.footer.pages.certifications") }}</h1>
      </div>
      <div class="lightbox" oncontextmenu="return false;">
        <LightBox />
      </div>
    </div>
  </div>
</template>

<script>
import LightBox from "@/components/LightBox.vue";

export default {
  components: { LightBox },
  name: "certification-page",
  data() {
    return {
      items: [
        {
          text: "Certificari",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped>
.certifications__page {
  width: 100%;
  display: flex;
  justify-content: center;
}

.certifications__page-container {
  width: 1171px;
  margin: 80px 0px;
}

.certifications__page-title h1 {
  font-family: "XMH Flama-Medium";
  text-transform: uppercase;
  color: #4d4d4e;
  font-size: 35px;
  margin-bottom: 70px;
}

.line-title {
  width: 65px;
  margin-top: -1px;
  margin-left: 0px;
  opacity: 1;
  border: 2px solid #01aad7;
  background-color: #01aad7;
}

@media (max-width: 992px) {
  .certifications__page-container {
    width: 85%;
  }
}
</style>