<template>
  <div class="apply__form">
    <div class="apply__form-h-title">
      <h1>{{ $t("careersPage.applyform.applyNow") }} {{ career[0].title }}</h1>
      <h3>{{ $t("careersPage.applyform.details") }}</h3>
    </div>
    <div class="apply__form-inputs">
      <b-form @submit="apply">
        <b-form-group
          id="input-group-1"
          :label="$t('careersPage.applyform.name')"
          label-for="input-1"
          type="text"
        >
          <b-form-input
            id="input-2"
            v-model="formData.full_name"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          :label="$t('careersPage.applyform.email')"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="formData.email"
            type="email"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-3"
          :label="$t('careersPage.applyform.letter')"
          label-for="input-3"
        >
          <b-form-textarea
            id="input-3"
            v-model="formData.letter_of_intent"
            type="textarea"
            rows="7"
            required
          ></b-form-textarea>
        </b-form-group>

        <b-form-file
          class="custom-field-cv"
          ref="file"
          v-on:change="onChange"
          :placeholder="$t('careersPage.applyform.files')"
          drop-placeholder="Drop file here..."
          accept="application/pdf,application/vnd.ms-excel"
        ></b-form-file>

        <!-- <div class="mb-3">
          <input class="form-control" type="file" id="formFile" />
        </div> -->
        <!--
        <div class="recaptcha">
          <vue-recaptcha
            sitekey="6LcYN_ohAAAAADOKXXI1OhC10SDsX2AGeWuOH0Rv"
          ></vue-recaptcha>
        </div> -->

        <h3>{{ $t("careersPage.applyform.reply") }}</h3>
        <b-button type="submit" class="apply__form-button">{{
          $t("careersPage.applyform.apply")
        }}</b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
// import { VueRecaptcha } from "vue-recaptcha";
import Career from "@/apis/Career";
import Admin from "@/apis/Admin";

export default {
  data() {
    return {
      career: [],
      formData: {
        full_name: "",
        email: "",
        letter_of_intent: "",
        file: "",
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getSingleCareer(this.$router.history.current.params.title);
    });
  },
  methods: {
    getSingleCareer(title) {
      Admin.getSingleCareer(title).then((response) => {
        this.career = response.data;
      });
    },
    async apply(e) {
      e.preventDefault();
      let data = new FormData();
      data.append("file", this.formData.file);
      data.append("full_name", this.formData.full_name);
      data.append("email", this.formData.email);
      data.append("letter_of_intent", this.formData.letter_of_intent);
      data.append("title", this.career[0].title);

      await Career.apply(data);

      window.showSuccess("Succes!", "Aplicatie trimisa cu succes");

      this.formData = {
        full_name: "",
        email: "",
        letter_of_intent: "",
        file: "",
      };

      this.$refs.file.reset();
    },

    onEvent() {
      this.$refs.recaptcha.execute();
    },

    onChange(e) {
      this.formData.file = e.target.files[0];
    },
  },
};
</script>
<style>
.apply__form {
  background-color: #ececec;
  padding: 70px;
  margin: 30px 0px 70px 0px;
}

.apply__form-h-title h1 {
  width: 100% !important;
  color: #4d4d4e;
  font-size: 35px;
  width: 700px;
  font-family: "XMH Flama-Medium";
}

.apply__form-h-title h3 {
  font-family: "XMH Flama-Basic";
  color: #4d4d4e;
  font-size: 21px;
  margin-bottom: 45px;
}

.form-group input {
  height: 53px;
  width: 100%;
}

.custom-file-label {
  height: 53px;
  width: 100%;
}

.custom-file {
  height: 53px;
}

.form-group {
  margin-bottom: 25px;
}

.apply__form-button {
  font-family: "XMH Flama-Medium";
  width: 170px;
  height: 46px;
  font-size: 13px;
  text-transform: uppercase;
  background-color: #00aad7;
  color: white;
  border: none;
  margin: 20px 0px;
  border-radius: 0;
  padding-top: 11px;
}

.apply__form-button:hover {
  border: 1px solid #00aad7;
  background-color: white;
  color: #00aad7;
}

.d-block {
  margin-bottom: 12px !important;
  font-size: 18px !important;
}

.apply__form-inputs h3 {
  font-size: 28px;
  color: #212e3d;
  margin: 40px 0px;
  font-family: "XMH Flama-Medium";
}

.custom-file {
  margin-bottom: 12px;
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  height: 51px !important;
}

.custom-field-cv .custom-file-label span {
  margin-bottom: 0px !important;
  margin-top: 6px !important;
}

@media (max-width: 992px) {
  .apply__form-h-title h1 {
    font-size: 28px;
  }
  .apply__form-h-title h3 {
    font-size: 18px;
    margin-bottom: 35px;
  }
  .apply__form {
    padding: 50px;
  }
  .apply__form-inputs h3 {
    font-size: 25px;
  }
  .custom-file-input ~ .custom-file-label[data-browse]::after,
  .custom-file-label {
    height: 42px !important;
  }
  .custom-field-cv .custom-file-label span {
    margin-top: 0px !important;
  }
}

@media (max-width: 850px) {
  .apply__now-form {
    width: 100% !important;
  }
}

@media (max-width: 500px) {
  .apply__form-h-title h1 {
    font-size: 24px;
  }
  .d-block {
    margin-bottom: 8px !important;
    font-size: 16px !important;
  }
  .form-group input {
    height: 42px;
  }
  .apply__form-inputs h3 {
    font-size: 22px;
  }
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  height: 55px;
  display: flex;
  align-items: center;
}

.custom-file-input.is-invalid ~ .custom-file-label {
  border: 1px solid #ced4da;
  padding-top: 11px;
}
</style>