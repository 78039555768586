<template>
  <b-container>
    <b-carousel controls img-width="1024" img-height="500" :interval="3000">
      <a v-for="item in items" :key="item.id" :href="item.link">
        <b-carousel-slide
          class="custom-height"
          :img-src="item.media[0].original_url"
          :caption="item.name"
          :text="item.subtitle"
        >
          <a :href="item.link"
            ><img id="custom-loupe" src="./../../assets/images/loupe.webp"
          /></a>
        </b-carousel-slide>
      </a>
    </b-carousel>
  </b-container>
</template>

<script>
import Admin from "@/apis/Admin.js";

export default {
  data() {
    return {
      items: [],
      slide: 0,
      sliding: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getHomepageSlider();
    });
  },
  methods: {
    getHomepageSlider() {
      Admin.getHomepageSlider().then((response) => {
        this.items = response.data;
      });
    },
  },
};
</script>

<style >
.custom-height img:not(.carousel-caption img) {
  height: 588px !important;
  object-fit: cover;
}

.carousel .carousel-indicators li {
  background-color: rgb(210, 183, 183);
  width: 40px;
  height: 5px;
  margin: 1px;
}

.carousel .carousel-indicators li.active {
  background-color: rgb(202, 202, 211);
}
.sr-only {
  display: none !important;
}

.carousel-caption {
  top: 47%;
  text-transform: uppercase;
  text-align: start;
  padding: 0 !important;
  left: 19%;
  right: auto !important;
  bottom: auto !important;
}

.carousel-caption h3 {
  position: relative;
  font-family: "XMH Flama-Book";
  color: #ffffff;
  font-size: 22px;
  margin-bottom: 27px;
  text-transform: uppercase;
}

.carousel-caption h3::after {
  content: "";
  position: absolute;
  background: #00aad7;
  width: 10%;
  height: 3px;
  bottom: 0;
  top: 128%;
  left: 0;
  right: 100%;
  margin: auto;
}

.carousel-caption p {
  font-size: 40px;
  width: 690px !important;
  font-family: "XMH Flama-Medium";
  color: #ffffff;
  line-height: 46.31px;
  text-transform: uppercase;
}

.carousel-control-prev {
  left: -45px;
}

.carousel-control-prev,
.carousel-control-next {
  top: 170px;
}

.carousel-control-prev-icon {
  background-image: url("./../../assets/images/carousel/left-arrow.webp");
  width: 37px;
  height: 49px;
}

.carousel-control-next-icon {
  background-image: url("./../../assets/images/carousel/next.webp");
  width: 37px;
  height: 49px;
}

@media (max-width: 1366px) {
  .carousel-caption {
    left: 7.5%;
  }
  .carousel-control-next {
    right: 0;
    top: 0px;
    width: 6% !important;
  }
  .carousel-control-prev {
    left: 0;
    top: 0px;
    width: 6% !important;
  }
  .carousel-caption p {
    width: 690px !important;
    line-height: 50px;
  }
  .carousel-caption {
    top: 33%;
  }
}

@media (max-width: 992px) {
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 28px;
    height: 34px;
  }
  .carousel-caption p {
    width: 430px !important;
    font-size: 24px;
    line-height: 30px;
  }
  .carousel-caption h3 {
    font-size: 18px;
  }
  #custom-loupe {
    width: 19px;
    height: 19px;
  }
  .custom-height img:not(.carousel-caption img) {
    height: 375px !important;
  }
}

@media (max-width: 600px) {
  .carousel-caption p {
    font-size: 19px;
    line-height: 25px;
  }
  .carousel-caption h3 {
    font-size: 15px;
  }
  .custom-height img:not(.carousel-caption img) {
    height: 310px !important;
  }
  .carousel-control-prev-icon, .carousel-control-next-icon {
    width: 20px;
    height: 26px;
}
}

@media (max-width: 450px) {
  .carousel-caption p {
    width: 280px !important;
    font-size: 15px;
    line-height: 20px;
  }
  .carousel-caption h3 {
    font-size: 12px;
  }
}
</style>