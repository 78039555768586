<template>
  <div class="industrial__walls-page">
    <div class="industrial__walls-breadcrumbs">
      <b-breadcrumb :items="items"></b-breadcrumb>
    </div>
    <div class="industrial__walls-content">
      <div class="industrial__walls-services row">
        <div class="col-md-6 industrial__walls-services-left-column">
          <div class="industrial__walls-title">
            <h1 class="line-after">
              {{ $t("homepage.header.menuValues.services") }}
            </h1>
          </div>
          <div class="industrial__walls-subtitle">
            <h2>{{ service.name }}</h2>
          </div>
          <div class="industrial__walls-text" v-html="service.content"></div>
        </div>
        <div class="col-md-6 industrial__walls-services-right-column">
          <div class="industrial__walls-services-image">
            <img :src="mainImage" />
            <figcaption class="industrial__walls-figcaption">
              {{ service.image_title }}
            </figcaption>
          </div>
        </div>
      </div>
    </div>

    <IndustrialWallsLightbox />

    <div
      class="
        industrial__walls-more-services
        w-100
        d-flex
        justify-content-center
      "
    >
      <div class="other__services-list">
        <div>
          <h1 class="line-after other__services-title">
            <span> {{ $t("homepage.anotherServ") }} </span>
          </h1>
        </div>
        <div class="other__services-item">
          <div
            class="other__services-description"
            v-for="item in services"
            :key="item.id"
            v-show="
              item.vue_components === 'assembly-structures' ||
              item.vue_components === 'maintenance-page'
            "
          >
            <a :href="item.slug + '&component=' + item.vue_components">
              <div v-for="media in item.media" :key="media.id">
                <img
                  v-if="media.collection_name === 'main_image'"
                  class="w-100"
                  :src="media.original_url"
                />
              </div>
              <h5 class="other__services-h5">{{ item.name }}</h5>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IndustrialWallsLightbox from "@/components/IndustrialWallsLightbox.vue";
import Admin from "@/apis/Admin.js";

export default {
  components: { IndustrialWallsLightbox },
  name: "industrial-walls",
  data() {
    return {
      items: [],
      service: [],
      mainImage: "",
      randomServices: [],
      services: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getWalls();
      this.getAllServices();
    });
  },
  methods: {
    getWalls() {
      const serviceName =
        this.$router.history.current.params.service.split("&")[0];
      Admin.getService(serviceName).then((response) => {
        this.service = response.data.service;
        this.randomServices = response.data.random_services;
        this.mainImage = this.service.main_image.original_url;
      });
    },
    getAllServices() {
      Admin.getAllServices().then((response) => {
        this.services = response.data;
      });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.breadcrumb {
  background: transparent;
}

.industrial__walls-breadcrumbs {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: url(./../../assets/images/servicii/montaj-pereti/bread-montaj-pereti.webp);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: calc(100vw / 4);
  max-height: 100%;
}

.breadcrumb span {
  font-size: 27px;
  text-transform: uppercase;
  font-weight: 500;
}

.industrial__walls-page {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.industrial__walls-services {
  width: 1171px;
}

.industrial__walls-content {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 60px 0px 30px 0px;
}

.industrial__walls-services-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-top: 6px;
}

.industrial__walls-title h1 {
  color: #252525;
  font-family: "XMH Flama-Medium";
  font-size: 24px;
  text-transform: uppercase;
}

.industrial__walls-subtitle h2 {
  color: #14afda;
  width: 75%;
  margin: 30px 0px 30px 0px;
  font-family: "XMH Flama-Medium";
  font-size: 36px;
  line-height: 40.93px;
}

.industrial__walls-text--margin-top {
  margin-top: 99%;
}

.industrial__walls-text h5 {
  color: #424242;
  font-family: "XMH Flama-Medium";
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 7px;
  margin-top: 30px;
}

.industrial__walls-text h4 {
  font-family: "XMH Flama-Medium";
  font-size: 16px;
  margin-top: 25px;
}

.industrial__walls-text p {
  font-family: "OpenSans-Regular";
  color: #424242;
  font-size: 14px;
  margin-bottom: 3px;
}

.industrial__walls-figcaption {
  width: auto;
  height: 66px;
  background-color: #e6e7e2;
  color: #72726f;
  padding-left: 41px;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.industrial__walls-gallery {
  margin: 30px 0px 80px 0px;
}

.industrial__walls-gallery-list {
  width: 1171px;
  --gap: 30px;
  --num-cols: 3;
  --row-height: 200px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  gap: var(--gap);
}

.industrial__walls-gallery-list-mobile {
  width: 1171px;
  --gap: 30px;
  --num-cols: 1;
  --row-height: 200px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  gap: var(--gap);
}

.industrial__walls-gallery-list-mobile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-grid-col-3 {
  grid-column: span 3;
}

.industrial__walls-gallery-list img {
  width: 100%;
}

.industrial__walls-services-right-column {
  margin-top: 13px;
}

.industrial__walls-more-services {
  background-color: #e6e7e2;
}

.other__services-title {
  color: #252525;
  font-family: "XMH Flama-Medium";
  font-size: 24px;
  margin: 20px 0px;
  text-transform: uppercase;
  margin-top: 70px;
}

@media (max-width: 1366px) {
  .industrial__walls-services,
  .industrial__walls-gallery-list {
    width: 85% !important;
  }
}

@media (max-width: 992px) {
  .industrial__walls-services {
    width: 85%;
  }
  .industrial__walls-title h1 {
    font-size: 20px;
  }
  .industrial__walls-subtitle h2 {
    width: 100%;
    margin: 25px 0px 30px 0px;
    font-size: 28px;
    line-height: 32px;
  }
  .industrial__walls-text h5 {
    font-size: 14px;
    line-height: 23px;
  }
  .industrial__walls-text p {
    font-size: 13px;
  }
  .industrial__walls-content {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .industrial__walls-services {
    display: flex;
    flex-direction: column-reverse;
    grid-row-gap: 35px;
  }
}
</style>