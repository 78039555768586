<template>
  <div class="navigation__footer">
    <div class="navigation__footer-content">
      <div class="row">
        <div class="col-md-2 navigation__footer-about-us">
          <div class="navigation__footer-about-us-title">
            <h4 class="navigation__footer-about-us-title">
              {{ $t("homepage.footer.titles.aboutUs") }}
            </h4>
            <hr class="separator-first" />
            <hr class="separator-second" />
          </div>
          <div class="navigation__footer-about-us-list">
            <ul>
              <li>
                <router-link
                  style="color: #e0e0e0"
                  :to="'/company/' + companyLangs[lang]"
                  >{{ $t("homepage.footer.pages.company") }}</router-link
                >
              </li>
              <li>
                <router-link style="color: #e0e0e0" to="/certificari">{{
                  $t("homepage.footer.pages.certifications")
                }}</router-link>
              </li>
              <li>
                <router-link style="color: #e0e0e0" to="/catalog">{{
                  $t("homepage.footer.pages.catalogue")
                }}</router-link>
              </li>
              <li>
                <router-link style="color: #e0e0e0" to="/cariere">{{
                  $t("homepage.footer.pages.careers")
                }}</router-link>
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'company-page',
                    params: { slug: companyLangs[lang] },
                    query: { source: 'footer' },
                  }"
                  style="color: rgb(224, 224, 224)"
                  >{{ $t("homepage.footer.pages.csr") }}</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        
          <div class="col-md-7 navigation__footer-contact">
              <div class="navigation__footer-contact-title">
                  <h4 class="navigation__footer-contact-title">
                      {{ $t("homepage.footer.titles.contact") }}
                  </h4>
                  <hr class="separator-first separator-first--custom-width" />
                  <hr class="separator-second" />
              </div>
              <div class="navigation__footer-contact-list">
                  <div class="navigation__footer-left-column" v-html="contact.column_1"></div>
                  <div class="navigation__footer-center-column" v-html="contact.column_2"></div>
                  <div class="navigation__footer-right-column" v-html="contact.column_3"></div>
              </div>
          </div>

        <div class="col-md-3 navigation__footer-newsletter">
          <div class="navigation__footer-subscribe">
            <SubscribeNewsletter />
          </div>
          <div class="navigation__footer-account">
            <h5 class="navigation__footer-account-title">
              {{ $t("homepage.footer.titles.account") }}
            </h5>
            <hr class="separator-first" />
            <hr class="separator-second" />
            <div class="navigation__footer-account-button">
              <a href="https://avinci.rabit.ro/" target="_blank"><button>{{ $t("homepage.footer.buttons.logIn") }}</button></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row column-reverse">
        <div class="col-md-9">
          <div class="navigation__footer-logo">
            <div>
              <a href="/"><img src="../assets/images/logo-light.webp" /></a>
            </div>
            <div class="navigation__footer-author">
              <p>
                © {{ new Date().getFullYear() }} Avinci -
                {{ $t("homepage.footer.copyright") }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="custom-sm-footer">
            <div class="navigation__footer-social-media">
              <div>
                <h4 class="navigation__footer-social-media-title">
                  {{ $t("homepage.footer.titles.followUs") }}
                </h4>
                <hr class="separator-first" />
                <hr class="separator-second" />
              </div>
              <div class="navigation__footer-social-media-icon">
                <a
                  v-for="(social, index) in socials"
                  :href="social.link"
                  target="_blank"
                  :key="index"
                >
                  <img
                    class="social-media-icon"
                    :src="social.footer_icon.original_url"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubscribeNewsletter from "@/components/SubscribeNewsletter.vue";
import Admin from "@/apis/Admin.js";
export default {
  components: { SubscribeNewsletter },
  name: "navigation-footer",
  data() {
    return {
      title: "NavigationFooter",
      contact: [],
      socials: [],
      companyLangs: {
        ro: "compania",
        en: "the-company",
        de: "das-unternehmen",
        fr: "la-société",
      },
      lang: localStorage.getItem("lang"),
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getContact();
      this.getSocial();
    });
  },
  methods: {
    getContact() {
      Admin.getContact().then((response) => {
        this.contact = response.data;
      });
    },
    getSocial() {
      Admin.getSocial().then((response) => {
        this.socials = response.data;
      });
    },
  },
};
</script>

<style scoped>
@import "../assets/styles/footer.css";
</style>
