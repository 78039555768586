<template>
  <div class="navigation__footer-news">
    <div class="navigation__footer-subscribe">
      <h4 class="navigation__footer-subscribe-title">
        {{ $t("homepage.footer.titles.subscribe") }}
      </h4>
      <hr class="separator-first" />
      <hr class="separator-second" />
    </div>
    <form
      @submit="subscribe"
      id="newsletter"
      class="navigation__footer-subscribe-input"
    >
      <div class="custom-inp">
        <input
          type="email"
          v-model="formState.email"
          placeholder="Mail"
        />
      </div>
      <div class="navigation__footer-subscribe-button">
        <button type="submit">{{ $t("homepage.footer.buttons.send") }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import Admin from "@/apis/Admin";

export default {
  data() {
    return {
      formState: {
        email: "",
      },
    };
  },
  methods: {
    subscribe(e) {
      e.preventDefault();
      Admin.subscribe(this.formState.email).then((response) => {
        response.data;
        window.showSuccess("Inregistrare email", "Emailul a fost inregistrat!");
        // this.service = response.data;
      });
    },
  },
};
</script>

<style scoped>
</style>