<template>
  <div id="app" class="h-screen">
    <NavigationHeader />
    <router-view></router-view>
    <NavigationFooter />
  </div>
</template>

<script>
import NavigationHeader from "@/components/NavigationHeader.vue";
import NavigationFooter from "@/components/NavigationFooter.vue";

export default {
  components: {
    NavigationHeader,
    NavigationFooter,
  },
};
</script>
<style>
/* XMH Flama Font */
@font-face {
  font-family: "XMH Flama-Basic";
  src: local("XMH Flama"),
    url(./assets/fonts/flama/XMHFlamaBasic.otf) format("truetype");
}

@font-face {
  font-family: "XMH Flama-Basic-Italic";
  src: local("XMH Flama"),
    url(./assets/fonts/flama/XMHFlamaBasicItalic.otf) format("truetype");
}

@font-face {
  font-family: "XMH Flama-Black";
  src: local("XMH Flama"),
    url(./assets/fonts/flama/XMHFlamaBlack.otf) format("truetype");
}

@font-face {
  font-family: "XMH Flama-Black-Italic";
  src: local("XMH Flama"),
    url(./assets/fonts/flama/XMHFlamaBlackItalic.otf) format("truetype");
}

@font-face {
  font-family: "XMH Flama-Bold";
  src: local("XMH Flama"),
    url(./assets/fonts/flama/XMHFlamaBold.otf) format("truetype");
}

@font-face {
  font-family: "XMH Flama-Bold-Italic";
  src: local("XMH Flama"),
    url(./assets/fonts/flama/XMHFlamaBoldItalic.otf) format("truetype");
}

@font-face {
  font-family: "XMH Flama-Book";
  src: local("XMH Flama"),
    url(./assets/fonts/flama/XMHFlamaBook.otf) format("truetype");
}

@font-face {
  font-family: "XMH Flama-Book-Italic";
  src: local("XMH Flama"),
    url(./assets/fonts/flama/XMHFlamaBookItalic.otf) format("truetype");
}

@font-face {
  font-family: "XMH Flama-Extra-Bold";
  src: local("XMH Flama"),
    url(./assets/fonts/flama/XMHFlamaExtrabold.otf) format("truetype");
}

@font-face {
  font-family: "XMH Flama-Extra-Bold-Italic";
  src: local("XMH Flama"),
    url(./assets/fonts/flama/XMHFlamaExtraboldItalic.otf) format("truetype");
}

@font-face {
  font-family: "XMH Flama-Light";
  src: local("XMH Flama"),
    url(./assets/fonts/flama/XMHFlamaLight.otf) format("truetype");
}

@font-face {
  font-family: "XMH Flama-Light-Italic";
  src: local("XMH Flama"),
    url(./assets/fonts/flama/XMHFlamaLightItalic.otf) format("truetype");
}

@font-face {
  font-family: "XMH Flama-Medium";
  src: local("XMH Flama"),
    url(./assets/fonts/flama/XMHFlamaMedium.otf) format("truetype");
}

@font-face {
  font-family: "XMH Flama-Medium-Italic";
  src: local("XMH Flama"),
    url(./assets/fonts/flama/XMHFlamaMediumItalic.otf) format("truetype");
}

@font-face {
  font-family: "XMH Flama-Thin";
  src: local("XMH Flama"),
    url(./assets/fonts/flama/XMHFlamaThin.otf) format("truetype");
}

@font-face {
  font-family: "XMH Flama-Thin-Italic";
  src: local("XMH Flama"),
    url(./assets/fonts/flama/XMHFlamaThinItalic.otf) format("truetype");
}

@font-face {
  font-family: "XMH Flama-Ultralight";
  src: local("XMH Flama"),
    url(./assets/fonts/flama/XMHFlamaUltralight.otf) format("truetype");
}

@font-face {
  font-family: "XMH Flama-Ultralight-Italic";
  src: local("XMH Flama"),
    url(./assets/fonts/flama/XMHFlamaUltralightItalic.otf) format("truetype");
}

/* Open Sans Font */
@font-face {
  font-family: "OpenSans-Bold";
  src: local("OpenSans"),
    url(./assets/fonts/open-sans/OpenSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans-BoldItalic";
  src: local("OpenSans"),
    url(./assets/fonts/open-sans/OpenSans-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans-ExtraBold";
  src: local("OpenSans"),
    url(./assets/fonts/open-sans/OpenSans-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans-ExtraBoldItalic";
  src: local("OpenSans"),
    url(./assets/fonts/open-sans/OpenSans-ExtraBoldItalic.ttf)
      format("truetype");
}

@font-face {
  font-family: "OpenSans-Italic";
  src: local("OpenSans"),
    url(./assets/fonts/open-sans/OpenSans-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans-Light";
  src: local("OpenSans"),
    url(./assets/fonts/open-sans/OpenSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans-LightItalic";
  src: local("OpenSans"),
    url(./assets/fonts/open-sans/OpenSans-LightItalic.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans-Regular";
  src: local("OpenSans"),
    url(./assets/fonts/open-sans/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans-Semibold";
  src: local("OpenSans"),
    url(./assets/fonts/open-sans/OpenSans-Semibold.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans-SemiboldItalic";
  src: local("OpenSans"),
    url(./assets/fonts/open-sans/OpenSans-SemiboldItalic.ttf) format("truetype");
}

@import "~bootstrap/dist/css/bootstrap.css";
@import "bootstrap-vue/dist/bootstrap-vue.css";
</style>
